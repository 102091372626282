/* eslint-disable max-lines */
export const COLOR1 = '#000000';
export const COLOR2 = '#FD0000';
export const COLOR3 = '#FDFDFD';
export const COLOR4 = '#ffffff';
export const COLOR5 = '#661C69';
export const COLOR6 = '#444444';
export const GRAY44 = '#707070';
export const ORIENTAL_ORANGE = '#E37617';
export const WHITE = '#fff';
export const BORDER_ACC_INFO = '#c1c1c1';
export const ORIENTAL_L_GRAY = '#6D6E70';
export const ORIENTAL_D_GRAY = '#555858';
export const BLACK_COLOR = '#5558582B';
export const BORDER_ORANGE = '#F5821F';
export const SMOKE_WHITE = '#F4F4F4';
export const GRAY95 = '#F2F2F2';
export const SEASHELL_COLOR = '#FBF3EC';
export const PURE_BLACK = '#000';
export const RED_COLOR = '#FD0000';
// main header
export const MAIN_HEADER_LOGO = 'Oriental Logo';
export const VALIDATION_BACKSPACE = 'Backspace';
export const AREA_CODE = '787';

export const UPLOAD_ID_PASSPORT = 'Upload ID or Passport';
export const UPLOAD_ID_PASSPORT_ARIA_LABEL = 'Upload ID or Passport Required';

export const TOOLTIP_ALTMSG = 'More Info';
export const TOOLTIP_POSITION_TOP = 'top';
export const TOOLTIP_POSITION_RIGHT = 'right';
export const YOUR_CONSENT = 'Your Consent';
export const YOUR_ACCOUNT = 'Your Account';
export const YOUR_ACCOUNT_BUSINESS = 'Your Account/Business';
export const YOUR_INFORMATION = 'Your Information';
export const PERSONAL_INFORMATION = 'Personal Information';
export const YOUR_INFORMATION_BUSINESS = 'Personal/Business Information';
export const LANGUAGE_ICON = 'Language Icon';
export const CHANGE_LANGUAGE = 'Change Language';
export const USER_LOGO = 'User Logo';
export const HELLO = 'Hello';
export const ORIENTAL_LOGO = 'Oriental-Logo';
// stepper connector
export const PRODUCT_PERSONAL = 'Personal';
export const PRODUCT_IRAFACIL = 'IRA Facil';
export const PRODUCT_IRAEDUCATION = 'Educational IRA';
export const PRODUCT_PERSONALCD = 'Personal CD';
// pages
export const CONTACT_INFO = 'contact-info';
export const BUSINESS_INFO = 'business-info';
export const PERSONAL_INFO = 'personal-info';
export const ADDRESS_INFO = 'address-info';
export const COMPLIANCE_INFO = 'compliance-info';
export const PRODUCT_INFO = 'product-info';
export const MYBIZ_EN = 'My Biz Account';
export const NONPROFIT = 'NON-PROFIT';
export const ESNON_PROFIT = 'SIN FINES DE LUCRO';
export const ESCROWACCEN = 'ESCROW ACCOUNT';
export const AHORRO = 'Ahorro Comercial';
export const COMMERCIAL_EN = 'Commercial CD';
export const COMMERCIAL_ES = 'CD Comercial';
export const BUSINESS_COMPLIANCE_INFO = 'business-compliance';
export const BUSINESS_ADDITIONAL_INFO = 'business-additional-info';
export const BUSINESS_SURI = 'business-suri';
export const BUSINESS_ACCOUNT_INFO = 'business-account-info';
export const BUSINESS_COMP = 'business-comp';
export const FUNDING_INFO = 'funding-info';
export const CONSENT_INFO = 'consent-info';
export const DGIADDITIONAL_INFO = 'dgiadditional-info';
export const FINANCE_INFO = 'finance-info';
export const EDELIVERY_INFO = 'edelivery-info';
// modal product que
export const PRODUCT_KEY_TEXTONE = 'ProductModalTextOne';
export const PRODUCT_KEY_TEXTTWO = 'ProductModalTextTwo';
// session time out modal
export const SESSION_MODAL_CONTENT =
  'To protect your personal information, we clear what you entered after 15 minutes of inactivity. Restart your online application anytime.';
export const SESSION_MODAL_TITLE = 'Your session has timed out.';
export const CLOSE = 'Close';
export const SAVEYOURAPPLICATION = 'Save your application';
export const CONTINUE = 'Continue';
export const BACK = 'Back';
export const INACTIVE_MODAL_MSG =
  'Your session is about to expire in 2 minutes due to inactivity. Do you wish to continue with your application?';
export const INACTIVEMODAL_TEXT_ONE = 'InactivityTexLineOne';
export const FOOTER_MSG = `Oriental. All Rights Reserved. FDIC Member.`;
export const FOOTER_KEY = `Footer`;
export const LOADER_ALT_MSG = 'Loading...';
export const SESSION_RESTART = 'Restart my application';
export const SESSION_MODAL_KEY = 'SessionModalTextLineTwo';
export const SESSION_TIMEOUT = 'Your session has timed out.';
// snapper
export const CONTACT_MODAL = 'DBA type accounts can only be opened by the owner !';
// modal and toggle
export const MODAL_PRODUCT_CONTENT =
  'As per Oriental Bank policy we are currently unable to establish a relationship with politically exposed persons, their family members and their close associates.';
export const MODALTEXTHEADING = 'We are not able to complete your application.';
export const MODALTEXTVOUCHEDHEADING = 'We are not able to complete your application.';
export const TOGGLEID = 'Bulk Cash Services';
// BUSINESS HOME ADDRESS
export const HOMEUS_PR = 'US-PR';
export const HOMEUS_VI = 'US-VI';
export const HOME_VI = 'VI';
export const HOME_PR = 'PR';
export const BODY_STYLE = 'body-style';
export const NOTNA = 'N/A';
export const HOME_USA = 'USA';
export const EU = 'EU';
export const FOR_YOU = 'For You';
export const FOR_YOU_PAGE = 'for-you';
export const DEVELOPMENT = 'development';
export const POP = 'POP';
export const HOME_ADDRESS_TITLE = 'We’d love to hear more about you!';
export const HOME_ADDRESS_TITLE_PROG = 'We’d love to hear more about you!';

export const HOME_ADDRESS_TITLE_COMMERCIAL = 'Your Home Address Information';
// 220,201
export const ADDRESS_LINE1 = 'Address Line 1';
export const ADDRESS_LINE2 = 'Address Line 2';
// contact info
export const CONTACT_HEADING = 'We’d love to hear about you and your business!';
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const PREFERRED_CONTACT_TIME = 'Preferred Contact Time';
export const MIDDLE_INIT = 'Middle Initial';
export const SEC_LAST_NAME = 'Second Last Name';
export const EMAIL = 'Email Address';
export const MOBILE = 'Mobile';
export const MOBILE_NUMBER = 'Mobile Number';
export const GENDER = 'Gender';
export const ALT_PHONE = 'Alternate Phone';
export const MARITAL_ST = 'Marital Status';
export const CONTACT_PRODUCT_HEADING = 'Let’s start with your contact information.';
// compliance page

export const MSG_MODAL_COMPL =
  'We currently do not offer bank products for the selected business type.';
export const KNOCK_OFF_MSSG_HEAD = 'We cannot complete your application.';
export const KNOCK_OFF_MSSG_BODY =
  'You are currently not eligible to open this account due to information on your profile.';

export const KNOCK_OFF_MSSG_BODY_ELIGIBILITY = `We’re sorry, we are not able to process your request at this time due to some information on your profile. Please continue as guest to open your account.`;
export const FUNDING_MSSG =
  'This account is not available for processing fund transfer. Please provide another account.';
export const COMPLIANCE_PRODUCT_HEADING =
  'Understanding your business type is an important step to get started.';
export const COMPLIANCE_QUESTION_ONE =
  'Is your company involved in any of the businesses listed below?';
export const COMPLIANCE_QUESTION_ONE_ARIA_LABEL =
  'I certify that my company is not involved in any of these listed 19 businesses.';
export const COMPLIANCE_QUESTION_TWO = 'Do you have an Active SURI Merchant Number?';
// businesscomp
export const COMP_HEADING = 'Is the company engaged in any of the following?';
export const COMP_TOGGLE1 = 'Bulk Cash Services';
export const COMP_TOGGLE2 = 'Fast Cash Services';
export const COMP_TOGGLE4 = `Private ATM's`;
export const COMP_TOGGLE5 = ' Act No. 20';
export const COMP_TOGGLE4_KEY = 'ATMRental';
export const COMP_ACT_KEY = 'Act20Cert';
export const COMP_UPLOAD_MSG = '(Upload your Act 20 Certification)';
export const COMP_UPLOAD_ATM_MSG = '(Upload ATM Rental or Purchase Documentation)';

export const COMP_MODAL_MSG =
  'As per Oriental Bank policy we are currently unable to establish a relationship with businesses which participate in internet gambling.';

// addditional info
export const ADDITIONAL_DBA = 'DBA';
export const ADDITIONAL_TITLE = 'More about your business';
export const ADDITIONAL_HEAD =
  'All fields are required to verify your information and to comply with federal regulations.';
// account info
export const ACC_INFO_DD = 'DD';
export const ACC_INFO_MM = 'MMM';
export const ACC_INFO_YYYY = 'YYYY';
export const ACC_INFO_STATE = 'state';
export const ACC_COUNTRY = 'country';
export const ACC_INFO_CITY = 'city';
export const ACC_INFO_ZIPCODE = 'zipCode';
export const ACC_VOUCHED = 'Vouched';
export const ACC_DOB = 'dob';
export const ACC_ADDRESS = 'address2';

export const WILL_YOU_BE_SIGNER = 'Will you be a signer on this account ?';

// surisearch
export const SURI_BUSINESS_NAME_MSG =
  'An active Merchant Number to confirm your Business Name and Business Owner Name is required.';
export const SURI_SER_TITLE = 'Search SURI Merchant Number';
export const ACTIVE_MERCHANT_NUMBER = 'Do you have an active Merchant Number ?';
export const SURI_SER_TEXT = 'Enter business information below:';
export const UNABLE_TO_FIND_MERCHANT_NUMBER =
  'We were unable to find your Merchant Number and details. Please enter your Merchant Number below:';
export const ENTER_MERCHANT_NUMBER = 'Enter your SURI Merchant Number details and search:';

// suri details
export const SURI_DETAIL_TITLE = 'Confirm Your Business Information';
// eslint-disable-next-line prettier/prettier
export const SURI_DETAIL_TEXT =
  'We have obtained your business information from SURI; please confirm this is your business:';
export const SURI_DETAIL_HEAD = 'Merchant Registration Certificate';

export const SURI_QUE = 'Is the information for your business correct?';
export const LOCATION_DETAILS = 'Location Details';
export const MERCHANT_DETAILS = 'Merchant Details';
export const CERTIFICATE_DETAILS = 'Certificate Details';
export const NAICS_CODES = 'NAICS Codes';
export const TYPE_OF_CERTIFICATE = 'Type Of Certificate';

// suri confirm
export const SURI_CONFIRM_HEAD = 'Confirm your SURI information';
export const BUSINESS_OWNER_HEAD = 'Business Owner Information';

export const SURI_CONFIRM_TEXT = 'Please let us know what information is incorrect:';
export const SURI_CONFIRM_LABEL1 = 'Business Owner Address';
export const SURI_CONFIRM_LABEL2 = 'Business Name';
export const SURI_CONFIRM_LABEL3 = 'Business Owner Name';
export const SURI_CONFIRM_LABEL4 = 'Merchant Number';
export const SURI_CONFIRM_LABEL5 = 'Business Address';
export const SURI_CHK = 'I certify that the above information is correct.';
export const SURI_SURI_HEAD = 'Please confirm if the information obtained from SURI is correct.';
export const SURI_SURI_HEAD_BEFORE_LOAD =
  'Let’s confirm your business information. We will use your SURI Merchant number for less data entry.';

export const COMP_ARRAY_VALUE1 = 'Money Services Business (MSB’s)';
export const COMP_ARRAY_VALUE2 = 'Bail Bond Businesses';
export const COMP_ARRAY_VALUE3 = 'Pre Paid Card Sale';
export const COMP_ARRAY_VALUE4 = 'Foreign Exchange Market';
export const COMP_ARRAY_VALUE5 = 'Pawn Shops';
export const COMP_ARRAY_VALUE6 = 'Offshore corporations';
export const COMP_ARRAY_VALUE7 = 'Collection Agencies';
export const COMP_ARRAY_VALUE8 = 'Casinos or Slot Machines';
export const COMP_ARRAY_VALUE9 = 'Foreign Correspondent Banks';
export const COMP_ARRAY_VALUE10 = 'Adult Entertainment Business';
export const COMP_ARRAY_VALUE11 = 'Precious Metal Dealers';
export const COMP_ARRAY_VALUE12 = 'Non-US Residents Aliens';
export const COMP_ARRAY_VALUE13 = 'Tarot Card Reading';
export const COMP_ARRAY_VALUE14 = 'International Banking Entities';
export const COMP_ARRAY_VALUE15 = 'Third Party Payment Processors';
export const COMP_ARRAY_VALUE16 = 'Gambling Businesses and Internet Gambling';
export const COMP_ARRAY_VALUE17 = 'Private ATM Machines';
export const COMP_ARRAY_VALUE18 = 'Credit Restoration Businesses';
export const COMP_ARRAY_VALUE19 =
  'Marijuana related business and services as well as drug paraphernilia related business';
export const SURI_MOD =
  'As per Oriental Bank policy we are currently unable to establish a relationship without an active Merchant Number.';
// DGI Screen after 3
export const DGIFINANCE_HEADING =
  'To comply with regulation it is important we obtain your financial information and evaluate your general investment knowledge. Please select below.';

export const DGIFINANCE_HEADING2 = 'Help us understand your General Investment Knowledge';
export const DGIFINANCE_HEADING1 = 'Your Financial Information';

export const DGI_STEPFOUR_HEAD =
  'Before we can open your account there is additional information about you we are required to know.';

export const DGI_ADDITIONAL_TRUST_HEAD = 'Trusted Contact Information';
export const DGI_ADDRESS = 'Address Line 1';
export const EMPLOYER_ADDRESS = 'Employer Address line 1';
export const DGI_EMP_ST = 'What is your Employment Status?';
export const DGI_REQ_QUESTIONS = 'Some questions we are required to ask:';
export const DGI_REQ_QUESTIONS_PROG = 'Some questions we are required to ask:';
export const SURI_UPLOAD_OWNERADD = '(Upload Business Owner address documentation)';
export const SURI_UPLOAD_ADD = '(Upload Business address documentation)';
export const SURI_MODAL_MSG =
  'As per Oriental Bank policy we are currently unable to establish a relationship due to the selected business type.';
export const DGI_POL_EXPOSED_QUE =
  'Are you a Politically Exposed Person or are you related to a Politically Exposed Person?';
export const DGI_POL_EXPOSED_QUE_PROG =
  'Are you a Politically Exposed Person or are you related to a Politically Exposed Person?';
export const DGI_SPEC_ADULT_QUE = 'Are you a Specified Adult?';
export const DGI_TRUST_CONT_QUE = 'Do you want to provide information for a Trusted Contact?';
export const DGI_EMPLOYMENT_STATUS = 'empStatus';
export const DGI_EMPLOYMENT_YEARS = 'empYears';
export const DGI_MODAL_POLI =
  'As per Oriental Bank policy we are currently unable to establish a relationship with politically exposed persons, their family members and their close associates.';

// edelivery
export const EXISTING_USER = 'existingUser';
export const NEW_USER = 'newUser';
export const RADIO_COLOR = '#661c69';
export const MODALTEXTHEADINGEDELIVERY = 'screen 7';
export const EXEMPT_PAYEE_CODE =
  'If you are an exempt payee (if you are unsure, ask us for a complete set of IRS instructions), enter your exempt payee code (if any) here:';
export const EXEMPT_FATCA_CODE =
  'If you are exempt from FATCA reporting (if you are unsure, ask us for a complete set of IRS instructions), enter your exemption from FATCA reporting code (if any) here:';
export const OCC_PLACEHOLDER = 'Enter your occupation details';
export const OCC_LABEL = 'Occupation';
export const EMP_LABEL = 'Employer';
export const REQUIRED = 'Required';
export const YEAR_PALCEHOLDER = 'Years';
export const TIME_LABEL = 'Employment Time Period';
export const YEAR_LABEL = 'Employment Time Period Years';
export const EMP_PLACEHOLDER = 'Enter Employer Name';
export const FONT_NORMAL_14_16 = 'normal normal normal 14px/16px Arial';
export const FONT_NORMAL_22 = 'normal normal normal 22px Arial';
export const FONT_BOLD_16_18 = 'normal normal bold 16px/18px Arial';
export const FONT_BOLD_20_23 = 'normal normal bold 20px/23px Arial';
export const BACKGROUND = `${COLOR3} 0% 0% no-repeat padding-box`;
export const DGI_ACCOUNT_ONLINE_ACCESS = 'DGI Balanced Fund IRA Account Online Access';
export const DGI_EDELIVERY_HEAD =
  'Use the options below to choose how you would like to receive your account information.';
export const EMPSTATUS_LABEL = 'Employment Status';
export const EMPSTATUS_LABEL_PROG = 'Employment Status';
export const SELECT_EMPSTATUS = 'Select Employment Status';

export const EMPSTATUS_ARIALABEL =
  'What is your Employment Status ? region Employment Status Required Select Employment Status dropdown menu button';
export const EMPSTATUS_ARIALABELKEY = 'Aria Label Employment Status';
export const EMPLOYED = 'Employed';
export const SELF_EMPLOYED = 'Self Employed';
export const NOT_EMPLOYED = 'Not Employed';
export const RETIRED = 'Retired';
export const NOTEMP_SCHOOLNAME = 'Enter School Name';
export const NOTEMP_SCHOOLNAMELABEL = 'School Name';
export const NOTEMP_STUDENT = 'Student';
export const NOTEMP_CURRENTINCOME = 'Enter Source of Current Income';
export const NOTEMP_CURRENTINCOME_LABEL = 'Source of Current Income';
export const NOTEMP_NOPREVEMP = 'No Previous Employer';
export const NOTEMP_CURRUINC_PLACEHOLDER = 'Enter Source of Current Income';
export const NOTEMP_CURRUINC = 'Source of Current Income';
export const NOTCURREMP = 'Not Currently Employed';
export const NOTCURREMP_EMPLOYER = 'Enter Employer Name';
export const NOTCURREMP_EMPLOYER_LABEL = 'Previous Employer';
export const MANDATORY_FIELD_ERR = '* Mandatory Field';
export const EN = 'EN';
export const ES = 'ES';
export const ENG = 'ENG';
export const ESP = 'ESP';
export const NOTEMP_REASON = 'reason';
export const NOTEMP_CURR_ERR = '* Invalid Source of Current Income';
export const NOTEMP_SCHOOL = 'schoolName';
export const NOTEMP_SCHOOL_ERR = '* Invalid School Name';
export const NOTEMP_PRE_EMP = 'previousEmployer';
export const NOTEMP_PRE_EMP_ERR = '* Invalid Previous Employer';
export const NOTEMP_SOURCE_CURR_INC = 'sourceOfCurrentIncome';
export const DGISELF = 'employer';
export const DGISELF_EMPLOYER_ERR = '* Invalid Employer Name';
export const INVALID_FORMAT_ERR = '* Invalid Format';
export const DGISELF_BUSINESSSNAME = 'Name of your Business';
export const DGISELF_BUSINESSSNAME_LABEL = 'Enter Business Name';
export const REASON = 'Reason';
export const REASON_ARIA_LABEL = 'Reason Required Please Select Reason dropdown menu button';
export const REASON_LABEL = 'Reason Aria Label';
export const REASON_QUE = 'Please select a reason';
export const FUND_UPLOAD_VOID_CHECK = 'Upload a void check or bank statement.';
export const INVALID_COUNTRY_MODAL_MESSAGE =
  'As per Oriental Bank Policy only US passports are accepted.';
export const MSG_COUNTRY_MODAL = "As per Oriental bank policy only US, PR, USVI ID's are accepted.";
export const MSG_VOUCHED_MODAL =
  'Upload a valid ID with your selfie to continue with your application.';
export const INVALID_ERR_ADDRESS = '* Invalid address line';
export const NUMERIC = 'numeric';
export const ZIPCODE_PLAC = 'Enter Zip Code';
export const ZIPCODE = 'Zip Code';
export const CITY = 'City';
export const CITY_LAB = 'City Required Choose your city dropdown menu button';
export const STATE_LAB = 'State Required Choose your state dropdown menu button';
export const STATE = 'State';
export const COUNTRY = 'Country/Region';
export const COUNTRY_LAB = 'Country Required Choose your country dropdown menu button';
export const ADD_PLAC = 'Enter Unit Number and Street';
export const COUNTRY_ARIA = 'Aria Label Country';
export const STATE_ARIA = 'Aria Label State';
export const CITY_ARIA = 'Aria Label City';
export const INVLABEL1 = 'What is your investment knowledge?';
export const INVLABEL1_O1 = 'None';
export const INVLABEL1_O2 = 'Limited';
export const INVLABEL1_O3 = 'Moderate';
export const INVLABEL1_O4 = 'Extensive';
export const INVLABEL1_ARIA =
  'What is your investment knowledge? Required Select What is your investment knowledge? dropdown menu button';
export const INV_LABEL_2 = 'Aria Label What is your investment knowledge?';
export const PLEASE_SELECT = 'Please Select';
export const EMP_STATUS_QUE = 'What is your employment status?';
export const EMP_STATUS_QUE_PROG = 'What is your employment status?';
export const OTHER = 'Other';
export const COMPLIANCE_HEAD =
  'Before we can open your account, there is additional information about you we are required to know.';
export const COMPLIANCE_HEAD_PROG =
  'Before we can open your account, there is additional information about you we are required to ask.';
export const MIN_DEPOSIT_ERR = '* Minimum deposit required is $';
export const MAX_DEPOSIT_ERR = '* Maximum deposit allowed is $5,000';
export const ADDRESS_INFO_HEAD =
  'Validating your address is an important step in identity confirmation. Review the information below and upload proof of address if your current home address is different from the address on your ID.';
export const ADDRESS_INFO_HEAD_PROG =
  'Validating your address is an important step in identity confirmation. Review the information below and upload proof of address if your current home address is different from the address on your ID.';
export const BUSINESS_TAX_ID = 'Enter Business Tax ID';
export const BUSINESS_TAX_ID_LABEL = 'Business Tax ID';
export const WEBSITE_LABEL = 'Website';
export const WEBSITE_PLACEHOLDER = 'Enter website address';
export const ANNUAL_SALES = 'Annual Sales';
export const NUMBER_OF_EMP = 'Number of Employees';
export const NET_WORTH = 'Net Worth';
export const LESS_THAN_500000 = 'Less than $ 500,000';
export const ANNUAL_SALES_KEY1 = '$ 500,000 to $ 1 Million';
export const ANNUAL_SALES_KEY2 = '$ 1 Million to $ 5 Million';
export const ANNUAL_SALES_KEY3 = 'Over $ 5 Million';
export const NET_WORTH_KEY1 = 'Less than $ 50,000';
export const NET_WORTH_KEY2 = '$ 50,000 - $ 100,000';
export const NET_WORTH_KEY3 = '$ 100,000 - $ 250,000';
export const NET_WORTH_KEY4 = '$ 250,000 - $ 500,000';
export const NET_WORTH_KEY5 = '$ 500,000 - $ 1 Million';
export const NET_WORTH_KEY6 = 'More than $ 1 Million';
export const NO_EMP_KEY1 = '1 - 10';
export const NO_EMP_KEY2 = '11 - 50';
export const NO_EMP_KEY3 = '51 - 100';
export const NO_EMP_KEY4 = '101 - 500';
export const NO_EMP_KEY5 = 'More than 500';
export const ID_BUSINESS_TAX_ID = 'businessTaxId';
export const ID_BUSINESS_WEBSITE = 'website';
export const ID_BUSINESS_NOOFEMP = 'noOfEmployees';
export const ID_BUSINESS_NETWORTH = 'netWorth';
export const ID_BUSINESS_ANNUAL_SALES = 'annualSales';
export const INV_LIQ_NEED_LABEL = 'What are your Liquidity Needs?';
export const LOW = 'Low';
export const MEDIUM = 'Medium';
export const HIGH = 'High';
export const CONTACT_DISCLAIMER =
  'By providing this information, you are giving your consent for Oriental Bank to communicate with you through call, text message and email.';
export const LETS_START_SUB_HEAD = 'Let’s start!';
export const PERSONAL_INFO_HEAD =
  "Upload your driver's license or passport first and we will complete the form using your information.";
export const PERSONAL_INFO_HEAD_PROG =
  "Upload your driver's license or passport first and we will complete the form using your information.";
export const PERSONAL_TEXT =
  'Save Time! Upload your ID to skip manually completing your information.';
export const UPLOAD_HOME_ADDRESS = 'Upload Home Address Proof';
export const PERSONAL_SUB_TEXT =
  'Your information will be encrypted and safeguarded in our system.';
export const MORE_ABOUT_YOU = 'More about you!';
export const ACC_OPENEING_DGI_HEAD =
  'Our online form helps us get to know you and your needs. Once completed, we will contact you to complete the request.';
export const ACC_OPENEING_HEAD =
  'Our online form helps us get to know you and your needs. Once completed, we will contact you to verify your identity and open your account.';
export const MAIL_HOME_ADDR_SAME = 'Mailing address same as home address';
export const DIFFERENT_MAIL_ADDR = 'My mailing address and home address are the same.';
export const TYPE_OF_ACCOUNT = 'Type of Account';
export const ACCOUNT_NUMBER = 'Account Number';
export const BANK = 'Bank';
export const BANK_NAME = 'Bank Name';
export const TRANSIT_NUMBER = 'Transit Number';
export const TAX_YEAR = 'Tax Year';
export const DGI_CONT_ACC_NUM = 'DGI Account number';
export const PROD_NM_DGI_CONTRIBUTION = 'DGI Contribution';
export const DGI_FUNDING_HEAD = 'Choose how you prefer to fund your account.';
export const FUNDING_HEAD =
  'Choose how you prefer to fund your account. We will process your deposit in 24-72 hours.';
export const FUNDING_TEXT = 'How would you like to make your first deposit ?';
export const FUNDING_DISCLAIMER =
  'By clicking continue I authorize ORIENTAL BANK to make a one-time electronic debit for the amount included in the deposit amount field.';
export const DEPOSIT_AMOUNT = 'Deposit Amount';
export const MINIMUM_DEPOSIT = 'Minimum deposit';
export const ENTER_ACCOUNT_NUMBER = 'Enter account number';
export const CONSENT_HELPER_TEXT_DGI =
  'Before we engage in this transaction, you must understand your rights and responsibilities and provide your consent.';
export const CONSENT_HELPER_TEXT =
  'Please read the following disclosures and account documents which provide important information related to your account.';
export const DIFFERENT_NUMBER_MESSAGE = 'Please provide a different number';
export const PERCENT = '100%';
export const VOUCHED_ELEMENT = '#vouched-element';
export const INVALID = 'Invalid';
export const VALID = 'Valid';
export const DOB_FORMAT = 'dd MMM yyyy';
export const DOB_DAY = 'dob_day';
export const IS_ADDR_ID_HOME_ADDR = 'Is the address provided in the ID Proof your home address ?';
export const DEBIT_CARD_REQUIRED_TEXT = 'Yes, mail my debit card.';
export const DEBIT_CARD_NOT_REQUIRED_TEXT = 'No, I don’t want a debit card for this account.';
export const DEBIT_CARD = 'Debit Card';
export const DEBIT_CARD_REQUIRED_TEXT_V2 = 'Yes, mail my card.';
export const DIRECT_DEPOSITE = 'Direct Deposit';
export const DIRECT_DEPOSITE_RADIO_TEXT = 'Yes, I’m interested.';
export const DIRECT_DEPOSITE_RADIO_TEXT_NO = 'No, I’m not interested.';
export const DIRECT_DEPOSITE_TEXT =
  'Make paydays even better with Direct Deposit. Sign up to receive your payroll or other deposits directly in your account.';
export const DEBIT_CARD_INFO_TEXT =
  'Your account includes a Mastercard Debit Card. Do you want us to ship you the card? (Arrives in 4-6 business days)';
export const DEBIT_CARD_INFO_TEXT_1 =
  'Your account includes a Mastercard debit card. Do you want the card to be shipped? (Arrives in 4-6 business days)';

export const UPLOAD_ADDRESS_PROOF_SURI_TEXT =
  'Use the edit icon to provide the correct address information. Upload address proof!';
export const OOPS_WE_HIT_A_SNAG = 'Oops we hit a snag!';
export const SURI_KNOCK_OFF_MESSAGE =
  'As per Oriental Bank policy an active SURI Merchant number is required to open this account.';
export const BANKRUPTCY_KNOCK_OFF_MESSAGE =
  'As per Oriental Bank policy we are currently unable to establish a relationship due to the business bankruptcy.';
export const YES = 'Yes';
export const NO = 'No';
// OCR
export const UTILITY_BILL = 'Utility Bill';
export const SELECT_UTILITY_BILL_TYPE = 'Select Utility Bill Type';
export const NAME_MISMATCH_ERROR =
  'The name provided in the application doesn’t match with the utility bill uploaded. Please provide another bill with the applicant name.';
export const DONE = 'DONE';
export const FAILED = 'FAILED';
export const CLICK_TO_UPLOAD = 'Click to upload again.';
export const BUSINESS_OWNERSHIP = 'Do you have ownership in the business?';
export const ADD_SIGNER_HEADING = 'Completing your application is easy now!';
export const ADD_SIGNER_SUB_HEAD =
  'Provide a link to additional participants by providing their contact detail and selecting their role below.';
export const BUSINESS_ACC_HEAD = `Let’s Understand your Participation in the Business`;
export const BUSINESS_ACC_MORE_ABOUT_YOU = 'More About You';
export const SIGNER_ADMIN_SNACKBAR_MESSAGE =
  'You can send invite to only one person with administrative control under this application.';
export const PRODUCTUI_PAGE_KEY = 'Product Page Text';
export const PRODUCTUI_PAGE_VALUE =
  'Please review the questions below which apply to your selected account and help us understand your intended use. Answer only if applicable.';
export const UPLOAD_ID_TEXT = 'By clicking below, you will navigate to an ID verification process';
export const DOC_CHECKLIST_MESSAGE = 'Upload the required documents to open your account.';
export const DOC_CHECKLIST_HEADING_MESSAGE = 'Uploading your documents is easy!';
export const DOC_CHECKLIST_SNACKBAR_MSG =
  'Proceed by uploading whichever documents you have. We will send a link to your email to upload any missing documents.';
export const DOC_UPLOAD_LATER_HEADING = 'Upload the required documents.';
export const ATLEAST_ONE_SIGNER_MESSAGE =
  'At least one additional participant needs to be added if the applicant is neither owner or signer';
export const EMAIL_ADD_DUPLICATE_MESSAGE =
  'Please provide an email address different from other applicants.';
export const EDELIVERYKEYDESC =
  'Before we can open your account we are required to obtain your consent on the following:';
export const INFO_SHOWN_BY_SYST_CORRECT =
  'The information of my business shown by the system is correct.';
export const I_CONFIRM_CHANGES_MADE_CORRECT = 'I confirm the changes made by me are correct.';
export const PROGRESA_CONTIGO = 'Progresa Contigo';
export const PROGRESA_CONTIGO_HEADING = `Our online form helps us get to know you and your needs. Upload driver's license or passport first and we will complete the form using your information.`;
export const UPLOAD_ADDRESS_GIF_TEXT =
  'Upload a utility bill with your name and address issued within the last 60 days.';
export const E_STATEMENTS = 'e-Statements';
export const ESTATEMENT_TEXT =
  'Your Libre account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our';
export const ESTATEMENT_BANKING = ' Online Banking.';
export const ADDRESSLINE1_MAX_ERR = '* Address Line 1 should have max 30 characters.';
export const ADDRESSLINE2_MAX_ERR = '* Address Line 2 should have max 30 characters.';
export const E_STATEMENT_MONTHLY_FEE =
  'If you prefer your statements on paper, you will have a monthly service charge of $5.00.';
export const E_STATEMENT_FEE_WAIVED_AGED =
  'Accounts whose primary signer is between ages 13 to 23 or 65 years of age or older, are exempt from this fee.';
export const ID_UPLOAD_TEXT =
  "Upload your photo ID to open your account. The ID's accepted are your driver's license, passport, passport card or US military ID";
export const SAVE_YOUR_PROGRESS = 'Save your progress';
export const PLEASE_VERIFY_YOUR_DETAILS = 'Please verify your details';
export const SAVE_APP_CONTACT_DISCLAIMER = `Your application progress will be saved and you will have 30 days to complete it. Once saved, you'll receive an email with instructions on how to retrieve and complete your application.`;
export const ADDRESS_EDIT_MODAL_TITLE = 'We noticed you want to edit your address';
export const ADDRESS_EDIT_REASON = 'What is the reason:';
export const ADDRESS_EDIT_RADIO_ONE = 'That is my address, but it has errors.';
export const ADDRESS_EDIT_RADIO_TWO = 'I have a new address.';
export const CAROUSAL_LOADER_TEXT =
  'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.';
export const WAITING_PERIOD_45_DAYS_ACCOUNT_OPENING =
  'You have 45 days from the date your account is opened to make your first deposit.';
export const RECEIVE_ACC_DETAILS_ON_EMAIL =
  'You will receive an email with your new account details in the next 24 to 48 hrs.';
export const COMBINED_STATEMENT =
  'Your account includes combined statement. You will be able to access you two statements together.';
export const RECEIVE_ACC_DETAILS_ON_EMAIL_PROG =
  'You will receive an email with your new account details in the next 24 to 48 business hours.';
export const REVIEW_APPLICATION =
  'We will review your application details in order to open your account.';
export const YOU_SUBMITTED_APP = 'You have successfully submitted your application.';
export const THANK_YOU = 'Thank You!';
export const WHAT_COME_NEXT = 'What comes next?';
export const INVALID_DATE = '* Invalid Date';
export const DATE_FORMAT_EN = 'mm/dd/yyyy';
export const DATE_FORMAT_ES = 'dd/mm/aaaa';
export const CAROUSEL_ONE_TITLE = 'Deposit, withdraw, send and receive money at your convenience.';
export const CAROUSEL_TWO_TITLE = 'Manage your money wherever you want, whenever you want.';
export const CAROUSEL_TWO_SUB_TITLE = 'With Online Banking and Digital Banking';
export const PHOTO_DEPOSIT = 'PHOTO Deposit';
export const LIVE_TELLER = 'Live Teller';
export const AUTOMATED_TELLER_MACHINE = 'Automated Teller Machine';
export const ATH_MOVIL = 'ATH Móvil';
export const PEOPLE_PAY = 'People Pay';
export const errorTypes = [
  'ERROR_OTP_VERIFY_FAILED',
  'ERROR_OTP_VERIFICAR_FALLO',
  'ERROR_ACCOUNT_LOCKED_TEMP',
  'ERROR_CUENTA_LOCKED_TEMP',
  'ERROR_ACCOUNT_LOCKED_TEMP_ONE_HR',
  'ERROR_CUENTA_LOCKED_TEMP_ONE_HR',
  'ERROR_ACCOUNT_LOCKED',
  'ERROR_CUENTA_BLOQUEADA',
  'ERROR_OTP_EXPIRED',
  'ERROR_OTP_EXPIREED',
];
export const CONTENT_TYPE = 'application/x-www-form-urlencoded';
export const ELITE_ACCOUNT_EN = 'Elite Account';
export const ELITE_ACCOUNT_ES = 'Cuenta Elite';
export const ESTATEMENT_SIGNER_FEE_EXEMPT =
  'Accounts whose primary signer is older than 65 years of age or older, are exempt from this fee.';
export const DEFICIT_TRANSFER_TEXT =
  'This account requires maintaining a minimum amount of $1,000.00 in the checking section. To help you maintain this balance, we will automatically transfer from your savings account the necessary amount to maintain this limit as you make transactions.';
export const EXCESS_TRANSFER_TEXT =
  'In order for you to earn the highest interest rate payments on your savings, we can automatically transfer the excess of the amount you want. Tell us if you prefer to activate this function along with the maximum amount and we will take care of the rest.';
export const EXCESS_TRANSFER_NOTE =
  'Note: We will transfer the excess of this amount from your checking to your savings section.';
export const FEATURE_INFO = 'feature-info';
export const KNOCK_OFF_MSSG_BODY_ELITE =
  'Some details of your profile do not match our records. You can open your Elite account through the new customer process, or call us at 787.434.8458 for support with the process.';
export const KNOCK_OFF_MSSG_BODY_AHORRA =
  'Some details of your profile do not match our records. You can open your Ahorra account through the new customer process.';
export const KNOCK_OFF_MSSG_BODY_INFO =
  'We will need more information to complete this request, please continue as guest to open your account.';
