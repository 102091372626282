/* eslint-disable max-lines */
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga';
import MainHeader from '../Header/MainHeader';
import Routing from '../Router/Routing';
import { getAccessToken } from '../../services/tokenService';
import { LeadGenerationProvider } from '../../context/LeadGenerationContext';
import {
  buildSession,
  getOrientalLogoImage,
  getProductQuestionTranslation,
} from '../../services/formService';
import Footer from '../Footer/Footer';
import InactivityModal from '../SessionTimeout/InactivityModal';
import SessionTimeoutModal from '../SessionTimeout/SessionTimeoutModal';
import SubmissionFailPage from '../LeadGeneration/SubmissionFailPage';
import { AREA_CODE, DEBIT_CARD_REQUIRED_TEXT, DIRECT_DEPOSITE_RADIO_TEXT } from '../../constants';
import { ping } from '../../services/pingService';

const THEME = createTheme({
  typography: {
    fontFamily: 'Arial',
  },
  overrides: {
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          border: '0.25px solid #555858',
        },
      },
    },
  },
});

const App = (props) => {
  const { i18n } = props;
  const [accessToken, setAccessToken] = useState('');
  const [otpToken, setOtpToken] = useState('');
  const [language, setLanguage] = useState('EN');
  const [location, setLocation] = useState('');
  const [productType, setProductType] = useState('');
  const [productName, setProductName] = useState('');
  const [utmCampaign, setUtmCampaign] = useState(null);
  const [utmSource, setUtmSource] = useState(null);
  const [utmMedium, setUtmMedium] = useState(null);
  const [utmContent, setUtmContent] = useState(null);
  const [utmTerm, setUtmTerm] = useState(null);
  const [continueModal, setContinueModal] = useState(false);
  const [branchCode, setBranchCode] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [currentFlow, setCurrentFlow] = useState('');
  const [isIE, setIsIE] = useState(false);
  const [username, setUserName] = useState('Guest');
  const [isApplicationSaved, setIsApplicationSaved] = useState(false);

  const [idUploadStatus, setIdUploadStatus] = useState(null);
  const [isSuccessRespFound, setIsSuccessRespFound] = useState(false);
  const [addressProofUploadStatus, setAddressProofUploadStatus] = useState(null);

  const [productQuestionsEN, setProductQuestionsEN] = useState({});
  const [productQuestionsES, setProductQuestionsES] = useState({});
  const [openProductQuestionModal, setOpenProductQuestionModal] = useState(false);

  const [consentValues, setConsentValues] = useState({});
  const [linkedConsentClickedValues, setLinkedConsentClickedValues] = useState({});

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [consentTimeStamps, setconsentTimeStamps] = useState({});

  const [employmentInformation, setEmploymentInformation] = useState({});
  const [employmentInformationErrors, setEmploymentInformationErrors] = useState({});

  const [openInactivityModal, setOpenInactivityModal] = useState(false);
  const [openSessionTimeoutModal, setOpenSessionTimeoutModal] = useState(false);

  const [timerInterval, setTimerInterval] = useState(null);
  const [pingTimerInterval, setPingTimerInterval] = useState(null);
  const [tokenTimerInterval, setTokenTimerInterval] = useState(null);
  const [singleAccDis, setSingleAccDis] = useState(false);
  const [isAllLinksClicked, setIsAllLinksClicked] = useState(false);
  const [applicationId, setApplicationId] = useState(null);

  const [contactInformation, setContactInformation] = useState({
    areaCode: AREA_CODE,
  });
  const [isModalSave, setIsModalSave] = useState(false);
  const [businessContactInformation, setBusinessContactInformation] = useState({
    areaCode: AREA_CODE,
    businessType: '',
  });
  const [inviFlowValues, setInviFlowValues] = useState({
    areaCode: AREA_CODE,
    phone: '',
    selectCorporateTitle: '',
    email: '',
    corporateTitle: '',
  });
  const [inviFlowValuesError, setInviFlowValuesError] = useState({
    areaCode: '',
    phone: '',
    selectCorporateTitle: '',
    email: '',
    corporateTitle: '',
  });

  const [businessContactInformationErrors, setBusinessContactInformationErrors] = useState({});
  const [contactInformationErrors, setContactInformationErrors] = useState({});
  const [personalInformation, setPersonalInformation] = useState({
    areaCode: AREA_CODE,
    altAreaCode: '787',
    dob_day: 'DD',
    dob_month: 'MMM',
    dob_year: 'YYYY',
    ssn: '',
    dob: '',
    lastName: '',
    unverifiedIdAddressVouched: false,
    vouchedIDExpiryDate: null,
  });
  const [personalInfoConsumer, setPersonalInfoConsumer] = useState({
    areaCode: AREA_CODE,
    altAreaCode: '787',
    firstName: '',
    middleName: '',
    lastName: '',
    secondLastName: '',
    phone: '',
    altPhone: '',
    ssn: '',
    dob: '',
    email: '',
  });
  const [personalInfoConsumerErrors, setPersonalInfoConsumerErrors] = useState({});

  const [customerType, setCustomerType] = useState('');
  const [plaidDetails, setPlaidDetails] = useState({
    flowType: '',
    status: '',
  });
  const [plaidRetry, setPlaidRetry] = useState(0);
  const [selectedOrientalAccountProgress, setSelectedOrientalAccountProgress] = useState({});
  const [plaidLinkTokenObj, setPlaidLinkTokenObj] = useState('');
  const [callPlaidLink, setCallPlaidLink] = useState(false);

  const [accNoListForOrientalAccount, setAccNoListForOrientalAccount] = useState([
    // {
    //   Id: '61f003f4d09d6e48c853945bdd0fa47dad367b70a79f6c0930f39d1ba54f2a8f',
    //   AccountNumber: '******3349',
    //   AccountType: 'DP',
    //   Balance: '3,406',
    // },
    // {
    //   Id: '79c373b9-0c78-41c2-8a8d-2bc9995a21e5',
    //   AccountNumber: '******3349',
    //   AccountType: 'DP',
    //   Balance: '999',
    // },
    // {
    //   Id: 'cf8af5a5-8b3a-475b-b22a-db2bb054b7fc',
    //   AccountNumber: '******3718',
    //   AccountType: 'EX',
    //   Balance: '0.0',
    // },
  ]);
  const [plaidSuccessDetails, setPlaidSuccessDetails] = useState({
    accId: '',
  });

  const [plaidAccNoLinked, setPlaidAccNoLinked] = useState([
    // {
    //   AccountNumber: '******9581',
    //   AccountType: 'DP',
    //   RoutingNo: '7878',
    // },
    // {
    //   AccountNumber: '******8109',
    //   AccountType: 'DP',
    //   RoutingNo: '78789898989',
    // },
    // {
    //   AccountNumber: '******3718',
    //   AccountType: 'EX',
    //   RoutingNo: '7878009',
    // },
  ]);

  const [isValidatingVouch, setIsValidatingVouch] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [personalInformationErrors, setPersonalInformationErrors] = useState({});
  const [addressInformation, setAddressInformation] = useState({
    sameMailingAddress: true,
    country: 'PR',
    state: 'N/A',
    permanentCountry: 'PR',
    permanentState: 'N/A',
    defaultCountryBasedOnLocation: 'PR',
    vouchedAddressVerified: false,
    vouchedAddrEdited: false,
    retainUtilityBill: false,
    homeAddressUploadDocumentId: null,
  });
  const [ocrErrorMessageArr, setOcrErrorMessageArr] = useState([]);
  const [addressInformationErrors, setAddressInformationErrors] = useState({});

  const [isScreenOneDirty, setIsScreenOneDirty] = useState(true);
  const [isScreenTwoDirty, setIsScreenTwoDirty] = useState(true);
  const [isScreenThreeDirty, setIsScreenThreeDirty] = useState(true);
  const [isScreenFourDirty, setIsScreenFourDirty] = useState(true);
  const [isScreenFiveDirty, setIsScreenFiveDirty] = useState(true);
  const [isFundingScreenDirty, setIsFundingScreenDirty] = useState(true);
  const [isBusinessContactInfoDirty, setIsBusinessContactInfoDirty] = useState(true);
  const [isBusinessComplianceDirty, setIsBusinessComplianceDirty] = useState(true);
  const [isBusinessAdditionalInfoDirty, setIsBusinessAdditionalInfoDirty] = useState(true);
  const [isBusinessSuriDirty, setIsBusinessSuriDirty] = useState(true);
  const [isFinanceInfoDirty, setIsFinanceInfoDirty] = useState(true);

  const [vouched, setVouched] = useState(null);
  const [vouchedIdErrorStatus, setVouchedIdErrorStatus] = useState(false);
  const [vouchedData, setVouchedData] = useState({});
  const [vouchedUploadCount, setVouchedUploadCount] = useState(0);

  const [items, setItems] = useState([0]);
  const [itemsAnswer, setItemsAnswer] = useState({});
  const [productData, setProductData] = useState({});
  const [formAnswer, setFormAnswer] = useState({});

  const [complianceItems, setComplianceItems] = useState([0]);
  const [complianceItemsAnswer, setComplianceItemsAnswer] = useState({});
  const [complianceData, setComplianceData] = useState({});
  const [act22DocID, setAct22DocID] = useState(null);
  const [act22DocUploadStatus, setAct22DocUploadStatus] = useState(null);
  const [act20DocUploadStatus, setAct20DocUploadStatus] = useState(null);
  const [act20DocID, setAct20DocID] = useState(null);
  const [ownerAddressSuriDocID, setOwnerAddressSuriDocID] = useState(null);
  const [addressSuriDocID, setAddressSuriDocID] = useState(null);
  const [addressSuriUploadStatus, setAddressSuriDocUploadStatus] = useState(null);
  const [ownerAddressSuriUploadStatus, setOwnerAddressSuriDocUploadStatus] = useState(null);

  const [chks, setChks] = useState(false);
  const [chk, setChk] = useState(false);
  const [vouchLastName, setVouchLastName] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [tokenVal, setTokenVal] = useState('');

  const [fundData, setFundData] = useState({
    accountTransferOrCheck: true,
    anotherBankAccount: false,
    dgiAccountNumber: '',
    statementUpload: false,
    debitCard: false,
    orientalAccount: true,
    expiryMonth: 'MMM',
    expiryYear: 'YYYY',
    accountNumber: '',
  });
  const [isBalanceValidationFail, setIsBalanceValidationFail] = useState(false);
  const [fundDataErrors, setFundDataErrors] = useState({});
  const [statementUploadStatus, setStatementUploadStatus] = useState(null);
  const [statementFileName, setStatementFileName] = useState('');
  const [statementError, setStatementError] = useState('');
  const [statementDocumentId, setStatementDocumentId] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);

  const [downloadAllDisabled, setDownloadAllDisabled] = useState(true);
  const [businessAdditionalInfo, setBusinessAdditionalInfo] = useState({
    noOfEmployees: '',
    netWorth: '',
    annualSales: '',
    website: '',
    businessTaxId: '',
  });

  const [businessAdditionalInfoErrors, setBusinessAdditionalInfoErrors] = useState({
    noOfEmployees: '',
    netWorth: '',
    annualSales: '',
    website: '',
    businessTaxId: '',
  });

  const [dgiFinanceInfo, setDgiFinanceInfo] = useState({
    annualIncome: '',
    netWorth: '',
    liquidNetWorth: '',
    taxBracket: '',
    investmentKnowledge: '',
    experienceInEquities: '',
    experienceInFixedIncome: '',
    experienceInMutualFunds: '',
    riskTolerance: '',
    investmentObjective: '',
    assetTimeHorizon: '',
    liquidityNeeds: '',
  });
  const [dgiFinanceInfoErrors, setDgiFinanceInfoErrors] = useState({
    annualIncome: '',
    netWorth: '',
    liquidNetWorth: '',
    taxBracket: '',
    investmentKnowledge: '',
    experienceInEquities: '',
    experienceInFixedIncome: '',
    experienceInMutualFunds: '',
    riskTolerance: '',
    investmentObjective: '',
    assetTimeHorizon: '',
    liquidityNeeds: '',
  });
  const [editableAddressError, setEditableAddressError] = useState({
    businessAddress: '',
    businessOwnerAddress: '',
  });
  const [signerSavedData, setSignerSavedData] = useState([]);
  const [isValidating, setIsValidating] = useState(false);
  const [applicantId, setApplicantId] = useState(null);
  const [suriSaveFlag, setSuriSaveFlag] = useState({
    editFlag: false,
    checkboxFlag: false,
    remap: false,
  });
  const [savedSuriDocs, setSavedSuriDocs] = useState([]);
  const [savedBusinessDocs, setSavedBusinessDocs] = useState([]);
  const [savedChecklistDocs, setSavedChecklistDocs] = useState([]);

  const [isBusinessBankruptcy, setIsBusinessBankruptcy] = useState(false);
  const [isBusinessBankruptcyNo, setIsBusinessBankruptcyNo] = useState(false);

  // SURI
  const [isCorrectSuriId, setIsCorrectSuriId] = useState(true);
  const [updatedSuri, setUpdatedSuri] = useState(null);
  const [newSuriDetails, setNewSuriDetails] = useState({});
  const [isCheckedSuri, setIsCheckedSuri] = useState(false);
  const [isActiveSuri, setIsActiveSuri] = useState(false);
  const [isActiveSuriNo, setIsActiveSuriNo] = useState(false);
  const [suriResponse, setSuriResponse] = useState({});
  const [ocrResponse, setOcrResponse] = useState(false);
  const [isOcrErrMessageFound, setIsOcrErrMessageFound] = useState(false);

  const [retrySuriData, setRetrySuriData] = useState({
    incorrectSuriAttempt: 0,
    activeSuriId: true,
    suriSearch: '',
  });
  const [isSuriSearchChanged, setIsSuriSearchChanged] = useState(false);
  const [retrySuriDataErrors, setRetrySuriDataErrors] = useState({
    suriSearch: '',
  });
  const [isValidSuriId, setIsValidSuriId] = useState(true);
  const [suriLang, setSuriLang] = useState(false);
  const [val, setval] = useState({
    businessAddressEdit: false,
    businessOwnerAddressEdit: false,
  });
  const [isCheckedCompliance, setIsCheckedCompliance] = useState(true);
  const [isCheckedActiveSURI, setIsCheckedActiveSURI] = useState(true);
  const [isCheckedBankruptcy, setIsCheckedBankruptcy] = useState(true);
  const [complianceVerifiedFirst, setComplianceVerifiedFirst] = useState(false);
  const [complianceVerifiedSecond, setComplianceVerifiedSecond] = useState(false);

  const [businessOwnerDetails, setBusinessOwnerDetails] = useState({
    businessAddress: '',
    businessOwnerAddress: '',
    suriId: '',
    isCorrectInfo: false,
  });
  const [editableAddress, setEditableAddress] = useState({
    businessAddress: '',
    businessOwnerAddress: '',
    isBAEdited: false,
    isBOEdited: false,
  });
  const [businessOwnerDetailsError, setBusinessOwnerDetailsError] = useState({});
  const [isBulk, setIsBulk] = useState(false);
  const [isFast, setIsFast] = useState(false);
  const [isGamb, setIsGamb] = useState(false);
  const [isAtm, setIsAtm] = useState(false);

  const [callLookupApi, setCallLookupApi] = useState(false);
  const [callLookupApiHome, setCallLookupApiHome] = useState(false);

  const [callLookupApiEmp, setCallLookupApiEmp] = useState(false);
  const [callLookupApiHomeSelfEmp, setCallLookupApiHomeSelfEmp] = useState(false);
  const [isAct, setIsAct] = useState(false);
  const [isvalidatingpoli, setIsValidatingpoli] = useState(false);
  const [isTrustInfoDirty, setIsTrustInfoDirty] = useState(true);
  const [ismodal, setIsModal] = useState(false);
  const [isModalSuri, setIsModalSuri] = useState(false);
  const [businessAccountInfo, setBusinessAccountInfo] = useState({
    ownerPercentage: '',
    ssn: '',
    dob: '',
    unverifiedIdAddressVouched: false,
    vouchedIDExpiryDate: null,
    dob_day: 'DD',
    dob_month: 'MMM',
    dob_year: 'YYYY',
  });

  const [businessUploaderror, setBusinessUploadError] = useState({
    atm: '',
    act: '',
    address: '',
    ownerAddress: '',
  });
  const [cancelActupload, setCancelActupload] = useState({});
  const [cancelAtmupload, setCancelAtmupload] = useState({});
  const [cancelAct22upload, setCancelAct22upload] = useState({});

  const [cancelEmancipationEvidence, setCancelEmancipationEvidence] = useState({});
  const [isBusinessAccountInfoDirty, setIsBusinessAccountInfoDirty] = useState(true);
  const [isBusinessCompDirty, setIsBusinessCompDirty] = useState(true);
  const [address, setAddress] = useState(true);

  const [atmDocID, setAtmDocID] = useState(null);
  const [atmDocUploadStatus, setAtmDocUploadStatus] = useState(null);
  const [businessAccountInfoError, setBusinessAccountInfoError] = useState({});
  const [businessHomeAddress, setBusinessHomeaddress] = useState({
    country: 'PR',
    state: 'N/A',
    permanentCountry: 'PR',
    permanentState: 'N/A',
    defaultCountryBasedOnLocation: 'PR',
    vouchedAddrEdited: false,
    homeAddressUploadDocumentId: null,
  });
  const [businessHomeAddressErrors, setBusinessHomeaddressErrors] = useState({});
  const [businessAccountIdUploadError, setBusinessAccountIdUploadError] = useState({
    uploadid: '',
  });
  const [isDGIScreenFourDirty, setIsDGIScreenFourDirty] = useState(true);

  const [trustedContactInformation, setTrustedContactInformation] = useState({
    areaCode: AREA_CODE,
    dob_day: 'DD',
    dob_month: 'MMM',
    dob_year: 'YYYY',
    country: 'PR',
    state: 'N/A',
    permanentCountry: 'PR',
    permanentState: 'N/A',
    defaultCountryBasedOnLocation: 'PR',
  });
  const [vouchedJob, setVouchedJob] = useState(null);
  const [dgiEmploymentStatus, setDgiEmploymentStatus] = useState({
    country: 'PR',
    empStatus: '',
    state: 'N/A',
    permanentCountry: 'PR',
    permanentState: 'N/A',
    defaultCountryBasedOnLocation: 'PR',
  });
  const [conEmploymentStatus, setConEmploymentStatus] = useState({
    empStatus: '',
    occupation: '',
    employer: '',
    empTime: '',
    phone: '',
    residenceStatus: '',
    residenceTime: '',
    rent: '',
    areaCode: '787',
  });
  const [conEmploymentStatusError, setConEmploymentStatusError] = useState({
    empStatus: '',
    occupation: '',
    employer: '',
    empTime: '',
    phone: '',
    residenceTime: '',
    residenceStatus: '',
    rent: '',
    areaCode: '',
  });
  const [addIncome, setAddIncome] = useState([]);

  const [dgiAdditionalInformation, setDgiAdditionalInformation] = useState({});

  const [trustedContactInformationErrors, setTrustedContactInformationErrors] = useState({});
  const [dgiEmploymentStatusErrors, setDgiEmploymentStatusErrors] = useState({});
  const [isCheckedTrustContact, setIsCheckedTrustContact] = useState(false);
  const [isCheckedAdult, setIsCheckedAdult] = useState(false);
  const [isCheckedPoli, setIsCheckedPoli] = useState(false);
  const [isCheckedPoliConsumer, setIsCheckedPoliConsumer] = useState(false);

  const [isheading, setIsHeading] = useState(false);
  const [servicedata, setServiceDate] = useState({
    isbulkSelected: '',
    isfastSelected: '',
    isgambSelected: '',
    isactSelected: '',
    isatmSelected: '',
  });
  // edelivery
  const [userIds, setUserIds] = useState({});
  const [userIdsErrors, setUserIdsErrors] = useState({ existingUserId: '' });
  const [selectedUserType, setSelectedUserType] = useState('existingUser');
  const [edeliveryInfo, setEdeliveryInfo] = useState({});
  const [codes, setCodes] = useState({});
  const [codesErrors, setCodesErrors] = useState({});
  const [isEdeliveryScreenDirty, setIsEdeliveryScreenDirty] = useState(true);
  const [onlineUserToggle, setOnlineUserToggle] = useState(true);
  const [msgModal, setMsgModal] = useState(true);
  const [addressLookupData, setAddressLookupData] = useState([]);
  const [addressLookupDataResponse, setAddressLookupDataResponse] = useState({});
  const [countryModal, setCountryModal] = useState(false);
  const [vouchedFailModal, setVouchedFailModal] = useState(false);
  const [debitCardDisburse, setDebitCardDisburse] = useState(true);
  const [eStatementDisburse, setEStatementDisburse] = useState(true);
  const [eStatementNotRequired, setEStatementNotRequired] = useState(false);
  const [debitCardNotRequired, setDebitCardNotRequired] = useState(false);
  const [isSuriServerFail, setIsSuriServerFail] = useState(false);

  const [depositeCardDisburse, setDepositeCardDisburse] = useState(DIRECT_DEPOSITE_RADIO_TEXT);
  const [depositeCardDisburseFeature, setDepositeCardDisburseFeature] = useState(
    DIRECT_DEPOSITE_RADIO_TEXT
  );
  const [isFundingRequired, setIsFundingRequired] = useState(DIRECT_DEPOSITE_RADIO_TEXT);
  const [excessTransferFeature, setExcessTransferFeature] = useState(DIRECT_DEPOSITE_RADIO_TEXT);
  const [debitCardDisburseFeature, setDebitCardDisburseFeature] =
    useState(DEBIT_CARD_REQUIRED_TEXT);
  const [excessAmt, setExcessAmt] = useState({ excessAmtVal: '' });
  const [excessAmtError, setExcessAmtError] = useState({ excessAmtVal: '' });

  const [noRadio, setNoRadio] = useState(false);
  const [yesRadio, setYesRadio] = useState(true);
  const [yesRadioPersonal, setYesRadioPersonal] = useState(true);
  const [noRadioPersonal, setNoRadioPersonal] = useState(false);
  const [yesRadioPersonalTrust, setYesRadioPersonalTrust] = useState(true);
  const [noRadioPersonalTrust, setNoRadioPersonalTrust] = useState(false);

  // doc checklist
  const [urlAppId, setUrlAppId] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState({
    corpResolution: '',
    certIncorp: '',
    articleIncorp: '',
    listOfOfficers: '',
    regCertificate: '',
    dbaResolution: '',
    LlcResolution: '',
    certOfOrg: '',
    opAgreement: '',
    deedOfTrust: '',
    certOfTrustReg: '',
    certOfIncumbency: '',
  });
  const [progress, setProgress] = useState({
    corpResolution: 0,
    certIncorp: 0,
    articleIncorp: 0,
    listOfOfficers: 0,
    regCertificate: 0,
    dbaResolution: 0,
    LlcResolution: 0,
    certOfOrg: 0,
    opAgreement: 0,
    deedOfTrust: 0,
    certOfTrustReg: 0,
    certOfIncumbency: 0,
  });
  const [docLabels, setDocLabels] = useState([]);
  // const [isClicked, setIsClicked] = useState(false);
  const [uploadDocId, setUploadDocId] = useState({
    corpResolution: '',
    certIncorp: '',
    articleIncorp: '',
    listOfOfficers: '',
    regCertificate: '',
    dbaResolution: '',
    LlcResolution: '',
    certOfOrg: '',
    opAgreement: '',
    deedOfTrust: '',
    certOfTrustReg: '',
    certOfIncumbency: '',
  });
  const [uploadStatus, setUploadStatus] = useState({
    corpResolution: '',
    certIncorp: '',
    articleIncorp: '',
    listOfOfficers: '',
    regCertificate: '',
    dbaResolution: '',
    LlcResolution: '',
    certOfOrg: '',
    opAgreement: '',
    deedOfTrust: '',
    certOfTrustReg: '',
    certOfIncumbency: '',
  });
  const [uploadGlobalStatus, setUploadGlobalStatus] = useState({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
    act22: '',
    emancipationevidence: '',
  });
  const [uploadGlobalFiles, setUploadGlobalFiles] = useState({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
    act22: '',
    emancipationevidence: '',
  });
  const [uploadGlobalDocId, setUploadGlobalDocId] = useState({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
    act22: '',
    emancipationevidence: '',
  });
  const [globalProgress, setGlobalProgress] = useState({
    act: 0,
    atm: 0,
    businessAddress: 0,
    businessOwnerAddress: 0,
    act22: 0,
    emancipationevidence: 0,
  });
  const [businessAddress, setBusinessAddress] = useState({});
  const [businessOwnerAddress, setBusinessOwnerAddress] = useState({});

  const [businessAddUploadTemp, setBusinessAddUploadTemp] = useState({
    uploadFile: '',
    uploadStatus: '',
    uploadDocId: '',
    Progress: '',
  });
  const [businessOwnerAddUploadTemp, setBusinessOwnerAddUploadTemp] = useState({
    uploadFile: '',
    uploadStatus: '',
    uploadDocId: '',
    Progress: '',
  });
  const [modalMessageGlobal, setModalMessageGlobal] = useState({
    messageTitle: '',
    messageSubTitle: '',
    typeName: '',
  });
  const [isErrorGlobal, setIsErrorGlobal] = useState(false);
  const [checklistErrGlobal, setChecklistErrGlobal] = useState({ size: '', type: '' });

  const [openModalGlobal, setOpenModalGlobal] = useState(false);
  const [flowInvitation, setFlowInvitation] = useState('');
  const [inviApplicationId, setInviApplicationId] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const [isChked, setIsChked] = useState(false);

  const [inviApplicantId, setInviApplicantId] = useState('');

  const [businessLegalNameInvi, setBusinessLegalNameInvi] = useState('');

  const [isDocChecklistDirty, setIsDocChecklistDirty] = useState(false);
  const [isCommercialProductUIDirty, setIsCommercialProductUIDirty] = useState(false);
  const [isFeatureScreenDirty, setIsFeatureScreenDirty] = useState(true);

  const [cancelBAupload, setCancelBAupload] = useState({});
  const [cancelBOAupload, setCancelBOAupload] = useState({});
  const [cancelDeed, setCancelDeed] = useState({});
  const [cancelCorpRes, setCancelCorpRes] = useState({});
  const [isGlobalFileUploadingAll, setIsGlobalFileUploadingAll] = useState({
    atm: false,
    act: false,
    businessAddress: false,
    businessOwnerAddress: false,
    act22: false,
  });
  const [disable, setDisable] = useState(false);

  const [cancelCertIncorp, setCancelCertIncorp] = useState({});
  const [cancelArtIncorp, setCancelArtIncorp] = useState({});
  const [cancelListOff, setCancelListOff] = useState({});
  const [cancelRegCer, setCancelRegCer] = useState({});
  const [cancelDbaRes, setCancelDbaRes] = useState({});
  const [cancelLlcRes, setCancelLlcRes] = useState({});
  const [cancelCertOrg, setCancelCertOrg] = useState({});
  const [cancelOpAgree, setCancelOpAgree] = useState({});
  const [cancelTrustReg, setCancelTrustReg] = useState({});
  const [cancelIncumbency, setCancelIncumbency] = useState({});
  const [accValidationErrCode, setAccValidationErrCode] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [accParticipant, setAccParticipant] = useState('');
  const [accParticipantErrors, setAccParticipantErrors] = useState('');
  const [authSigner, setAuthSigner] = useState([]);
  const [owner, setOwner] = useState([]);
  const [beneficialOwner, setBeneficialOwner] = useState([]);
  const [adminControl, setAdminControl] = useState([]);
  const [coSigner, setCoSigner] = useState([]);

  const [ownership, setOwnership] = useState(false);
  const [signerCheck, setSignerCheck] = useState(false);
  const [isAccordianError, setIsAccordianError] = useState([]);
  const [flowTypeUpload, setFlowTypeUpload] = useState('');
  const [flowTypeQRcode, setFlowTypeQRcode] = useState('');
  const [profileDetails, setProfileDetails] = useState({});
  const [addressDocLoading, setAddressDocLoading] = useState(false);
  const [statementUploadLoading, setStatementUploadLoading] = useState(false);
  const [businessOwnerManualInfo, setBusinessOwnerManualInfo] = useState({});

  const [countries, setCountries] = useState([]);
  const [receivedCountryData, setReceivedCountryData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [enterCountry, setEnterCountry] = useState('');
  const [tabKey, setTabKey] = useState({ key: Boolean, click: Boolean });
  const [internaltionalWireTransferError, setInternationalWiretransferError] = useState(false);
  const [isQrCodeDirty, setIsQrCodeDirty] = useState(true);
  const [qrCodeVal, setQrCodeVal] = useState({
    productName: '',
    productCat: '',
    selectLang: '',
    custName: '',
    salesAdvisor: '',
    emailAdd: '',
  });
  const [qrCodeValError, setQrCodeValError] = useState({
    productName: '',
    productCat: '',
    selectLang: '',
    custName: '',
    salesAdvisor: '',
    emailAdd: '',
  });
  const [etbLogin, setEtbLogin] = useState({
    ssn: '',
    dob: '',
  });
  const [isLogin, setIsLogin] = useState(false);
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [isQR, setIsQR] = useState(false);
  const [salesAdvisor, setSalesAdvisor] = useState('');
  const [organization, setOrganization] = useState('');
  const [newAddress, setNewAddress] = useState(false);
  const [addressWithErrors, setAddressWithErrors] = useState(false);
  const [popupModalIntial, setPopupModalIntial] = useState(true);
  const [addressEditQuestionModal, setAddressEditQuestionModal] = useState(false);
  const [isHandleConti, setIsHandleConti] = useState({ one: false, two: false });
  const [isDebitSettled, setIsDebitSettled] = useState(true);
  const [isDebitNotSettled, setIsDebitNotSettled] = useState(false);
  const [saveAppContactInfo, setSaveAppContactInfo] = useState({
    dob_day: 'DD',
    dob_month: 'MMM',
    dob_year: 'YYYY',
    email: '',
    ssn: '',
    dob: '',
    lastName: '',
  });
  const [saveAppContactInfoErrors, setSaveAppContactInfoErrors] = useState({});
  const [saveAppContactScreenDirty, setSaveAppContactScreenDirty] = useState(true);
  const [returnTosaveApp, setReturnTosaveApp] = useState({
    dob_day: 'DD',
    dob_month: 'MMM',
    dob_year: 'YYYY',
    ssn: '',
    dob: '',
    lastName: '',
  });
  const [vouchdob, setVouchdob] = useState('');

  const [returnTosaveAppErrors, setReturnTosaveAppErrors] = useState({});
  const [returnTosaveAppScreenDirty, setReturnTosaveAppScreenDirty] = useState(true);
  const [isModalSaveLater, setIsModalSaveLater] = useState(false);
  const [savelaterVal, setSaveLaterVal] = useState([]);
  const [flowTypeVerifyScreen, setFlowTypeVerifyScreen] = useState('');
  const [flowTypePlaidVerifyScreen, setFlowTypePlaidVerifyScreen] = useState('');
  const [plaidVerification, setPlaidVerification] = useState({
    dob_day: 'DD',
    dob_month: 'MMM',
    dob_year: 'YYYY',
    ssn: '',
    dob: '',
  });
  const [plaidVerificationErrors, setPlaidVerificationErrors] = useState({});
  const [savedDataFlow, setSavedDataFlow] = useState(false);
  const [userSavedPage, setUserSavedPage] = useState('');
  const [savedItemsAnswer, setSavedItemsAnswer] = useState({});
  const [savedFormAnswer, setSavedFormAnswer] = useState({});
  const [automatedAccDetails, setAutomatedAccDetails] = useState({});
  const [userOptionAddress, setUserOptionAddress] = useState('');
  const [idError, setIdError] = useState(null);
  // otp
  const [attemptLeftInOptionSelect, setAttemptLeftInOptionSelect] = useState(0);

  const [receiveCodeState, setReceiveCodeState] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [initialPayLoading, setInitialPayLoading] = useState(false);
  const [otpDataEmail, setOtpDataEmail] = useState('');
  const [deliveryMedium, setDeliveryMedium] = useState('');
  const [customerVerifyToken, setCustomerVerifyToken] = useState(null);
  const [customerNumber, setCustomerNumber] = useState('');
  const [detailAcc, setDetailAcc] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [state, setState] = useState({
    accountNumber: '',
    ssn: '',
    error: false,
    prefferedOption: '',
    deviceId: '',
    // prefferedOption: 'email',
  });
  const [loginEmail, setLoginEmail] = useState('');

  const [emailList, setEmailList] = useState([]);
  const [customerGuid, setCustomerGuid] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [loginAttemptCounter, setLoginAttemptCounter] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [phoneList, setPhoneList] = useState([]);
  const [lightAuthToken, setLightAuthToken] = useState(null);
  const [contactInfoDetails, setContactInfoDetails] = useState({});
  const [initialCallFlag, setInitialCallFlag] = useState(false);
  const [languageETBflow, setLanguageETBflow] = useState('EN');
  const [flowTypeETB, setFlowTypeETB] = useState('');
  const [customerAppId, setCustomerAppId] = useState('');
  const [loginDetails, setLoginDetails] = useState({
    phone: '',
    areaCode: '787',
    email: '',
    addresses: {},
  });
  const [selectedAddress, setSelectedAddress] = useState({});
  const [loginDetailsErrors, setLoginDetailsErrors] = useState({});
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isEtbAddressDirty, setIsEtbAddressDirty] = useState(true);
  const [loginHomeAddress, setLoginHomeAddress] = useState({
    country: 'PR',
    state: 'N/A',
    permanentCountry: 'PR',
    permanentState: 'N/A',
    defaultCountryBasedOnLocation: 'PR',
  });
  const [loginHomeAddressErrors, setLoginHomeAddressErrors] = useState({});
  const [reFlag, setReFlag] = useState(false);
  const [sessionCallFlag, setSessionCallFlag] = useState(false);
  const [sessionCallFlagConsent, setSessionCallFlagConsent] = useState(false);
  const [accInfoAddToggleDisable, setAccInfoAddToggleDisable] = useState(false);
  const [vouchedResponseAddr, setVouchedResponseAddr] = useState({});
  const [disableContinue, setDisableContinue] = useState(false);
  const [msgPopUp, setMsgPopUp] = useState(false);
  const handleOnIdle = () => {
    setOpenInactivityModal(false);
    setOpenSessionTimeoutModal(true);
    setAccessToken('');
  };
  const [checklistErr, setChecklistErr] = useState({ size: '', type: '' });
  const [microVerifyError, setMicroVerifyError] = useState('');
  const [microPlaidPopUp, setMicroPlaidPopUp] = useState({
    snackbar: false,
    modal: false,
  });
  const [plaidStatementMandatoryError, setPlaidStatementMandatoryError] = useState(false);
  const [crossCheckResponse, setCrossCheckResponse] = useState({});
  const [vouchedImage, setVouchedImage] = useState('');

  const handleOnAction = () => {
    if (openInactivityModal === true) {
      setOpenInactivityModal(false);
    }
  };
  const { getRemainingTime, isIdle, reset, start, pause } = useIdleTimer({
    timeout: 900000,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    startManually: true,
    stopOnIdle: true,
    events: [
      'DOMMouseScroll',
      'change',
      'click',
      'input',
      'keypress',
      'submit',
      'keydown',
      'keyup',
    ],
  });

  const startTimer = () => {
    start();
    const interval = setInterval(() => {
      if (isIdle()) {
        clearInterval(interval);
        return;
      }
      if (getRemainingTime() < 120000 && !isIdle()) {
        if (openInactivityModal !== true) {
          setOpenSessionTimeoutModal(false);
          setOpenInactivityModal(true);
        }
      }
    }, 1000);
    setTimerInterval(interval);
  };
  const pingTimer = (token) => {
    const pingInterval = setInterval(() => {
      if (isIdle()) {
        clearInterval(pingInterval);
        return;
      }
      if (token) {
        ping(token);
      }
    }, 300000);
    setPingTimerInterval(pingInterval);
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT === 'production' ||
      process.env.REACT_APP_ENVIRONMENT === 'development'
    ) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);
  useEffect(() => {
    const session = async () => {
      const responseSession = await buildSession(customerAppId, accessToken);
      if (responseSession && responseSession.status === 200) {
        setSessionCallFlag(true);
        setSessionCallFlagConsent(true);
      }
    };
    if (customerAppId && reFlag && accessToken) {
      session();
    }
  }, [accessToken]);
  useEffect(() => {
    async function getToken() {
      const token = await getAccessToken();
      if (token) {
        ping(token);
        setAccessToken(token);
        pingTimer(token);
      }
    }
    getToken();
    const tokenInterval = setInterval(() => {
      if (isIdle()) {
        clearInterval(tokenInterval);
        return;
      }

      getToken();
    }, 1500000);

    setTokenTimerInterval(tokenInterval);
  }, []);

  useEffect(() => {
    startTimer();
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');
    const isIEBrowser = msie > 0 || trident > 0;
    setIsIE(isIEBrowser);
  }, []);

  useEffect(async () => {
    if (accessToken) {
      const productQuestionsENResponse = await getProductQuestionTranslation(accessToken, 'EN');
      if (productQuestionsENResponse && productQuestionsENResponse.data) {
        setProductQuestionsEN(productQuestionsENResponse.data);
      }
      const productQuestionsESResponse = await getProductQuestionTranslation(accessToken, 'ES');
      if (productQuestionsESResponse && productQuestionsESResponse.data) {
        setProductQuestionsES(productQuestionsESResponse.data);
      }

      const VouchedImage = await getOrientalLogoImage(accessToken);
      setVouchedImage(VouchedImage?.data?.preSignedUrl);
    }
  }, [accessToken]);

  const setWebPageLanguage = (value) => {
    i18n.changeLanguage(value);
    if (value === 'ESP') {
      setLanguage('ES');
      document.documentElement.lang = 'es';
    } else {
      setLanguage('EN');
      document.documentElement.lang = 'en';
    }
  };

  const pauseTimerAndClearInterval = () => {
    pause();
    clearInterval(timerInterval);
  };

  const clearPingTimer = () => {
    clearInterval(pingTimerInterval);
  };

  return (
    <div style={{ height: '100%' }}>
      <MuiThemeProvider theme={THEME}>
        <LeadGenerationProvider
          value={{
            accessToken,
            setAccessToken,
            language,
            setLanguage,
            location,
            setLocation,
            productType,
            setProductType,
            productName,
            setProductName,
            utmCampaign,
            setUtmCampaign,
            utmSource,
            setUtmSource,
            utmMedium,
            setUtmMedium,
            utmContent,
            setUtmContent,
            utmTerm,
            setUtmTerm,
            branchCode,
            setBranchCode,
            firstName,
            setFirstName,
            currentPage,
            setCurrentPage,
            isIE,
            setIsIE,
            productData,
            setProductData,
            act22DocID,
            setAct22DocID,
            act22DocUploadStatus,
            setAct22DocUploadStatus,
            formAnswer,
            setFormAnswer,
            idUploadStatus,
            setIdUploadStatus,
            addressProofUploadStatus,
            setAddressProofUploadStatus,
            productQuestionsEN,
            setProductQuestionsEN,
            productQuestionsES,
            setProductQuestionsES,
            openProductQuestionModal,
            setOpenProductQuestionModal,
            consentValues,
            setConsentValues,
            linkedConsentClickedValues,
            setLinkedConsentClickedValues,
            submitDisabled,
            setSubmitDisabled,
            consentTimeStamps,
            setconsentTimeStamps,
            employmentInformation,
            setEmploymentInformation,
            employmentInformationErrors,
            setEmploymentInformationErrors,
            openInactivityModal,
            setOpenInactivityModal,
            openSessionTimeoutModal,
            setOpenSessionTimeoutModal,
            timerInterval,
            setTimerInterval,
            pingTimerInterval,
            setPingTimerInterval,
            isAllLinksClicked,
            setIsAllLinksClicked,
            applicationId,
            setApplicationId,
            contactInformation,
            setContactInformation,
            contactInformationErrors,
            setContactInformationErrors,
            businessContactInformation,
            setBusinessContactInformation,
            businessContactInformationErrors,
            setBusinessContactInformationErrors,
            personalInformation,
            setPersonalInformation,
            personalInformationErrors,
            setPersonalInformationErrors,
            customerType,
            setCustomerType,
            addressInformation,
            setAddressInformation,
            addressInformationErrors,
            setAddressInformationErrors,
            isScreenOneDirty,
            setIsScreenOneDirty,
            isScreenTwoDirty,
            setIsScreenTwoDirty,
            isScreenThreeDirty,
            setIsScreenThreeDirty,
            isFundingScreenDirty,
            setIsFundingScreenDirty,
            fundData,
            setFundData,
            fundDataErrors,
            setFundDataErrors,
            vouched,
            setVouched,
            items,
            setItems,
            itemsAnswer,
            setItemsAnswer,
            setWebPageLanguage,
            pauseTimerAndClearInterval,
            clearPingTimer,
            pingTimer,
            isScreenFourDirty,
            setIsScreenFourDirty,
            complianceItems,
            setComplianceItems,
            complianceItemsAnswer,
            setComplianceItemsAnswer,
            complianceData,
            setComplianceData,
            isScreenFiveDirty,
            setIsScreenFiveDirty,
            statementUploadStatus,
            setStatementUploadStatus,
            statementDocumentId,
            setStatementDocumentId,
            downloadAllDisabled,
            setDownloadAllDisabled,
            bankDetails,
            setBankDetails,
            vouchedIdErrorStatus,
            setVouchedIdErrorStatus,
            vouchedData,
            setVouchedData,
            vouchedUploadCount,
            setVouchedUploadCount,
            currentFlow,
            setCurrentFlow,
            startTimer,
            businessAdditionalInfo,
            setBusinessAdditionalInfo,
            businessAdditionalInfoErrors,
            setBusinessAdditionalInfoErrors,
            isActiveSuriNo,
            setIsActiveSuriNo,
            isBusinessBankruptcy,
            setIsBusinessBankruptcy,
            isBusinessBankruptcyNo,
            setIsBusinessBankruptcyNo,
            isCheckedBankruptcy,
            setIsCheckedBankruptcy,
            isCheckedSuri,
            setIsCheckedSuri,
            isActiveSuri,
            setIsActiveSuri,
            isCheckedCompliance,
            setIsCheckedCompliance,
            isCheckedActiveSURI,
            setIsCheckedActiveSURI,
            complianceVerifiedFirst,
            setComplianceVerifiedFirst,
            complianceVerifiedSecond,
            setComplianceVerifiedSecond,
            suriResponse,
            setSuriResponse,
            signerSavedData,
            setSignerSavedData,
            isBusinessContactInfoDirty,
            setIsBusinessContactInfoDirty,
            isBusinessComplianceDirty,
            setIsBusinessComplianceDirty,
            isBusinessAdditionalInfoDirty,
            setIsBusinessAdditionalInfoDirty,
            isBusinessSuriDirty,
            setIsBusinessSuriDirty,
            retrySuriData,
            setRetrySuriData,
            businessUploaderror,
            setBusinessUploadError,
            retrySuriDataErrors,
            setRetrySuriDataErrors,
            val,
            setval,
            businessAccountInfoError,
            setBusinessAccountInfoError,
            setBusinessAccountInfo,
            businessAccountInfo,
            atmDocUploadStatus,
            setAtmDocUploadStatus,
            atmDocID,
            setAtmDocID,
            isBulk,
            setIsBulk,
            isFast,
            setIsFast,
            isGamb,
            setIsGamb,
            isAtm,
            setIsAtm,
            isAct,
            setIsAct,
            businessOwnerDetails,
            businessAccountIdUploadError,
            setBusinessAccountIdUploadError,
            setBusinessOwnerDetails,
            businessOwnerDetailsError,
            setBusinessOwnerDetailsError,
            servicedata,
            setServiceDate,
            isheading,
            setIsHeading,
            act20DocUploadStatus,
            setAct20DocUploadStatus,
            act20DocID,
            setAct20DocID,
            isCorrectSuriId,
            setIsCorrectSuriId,
            newSuriDetails,
            setNewSuriDetails,
            updatedSuri,
            isBusinessCompDirty,
            setIsBusinessCompDirty,
            isBusinessAccountInfoDirty,
            setIsBusinessAccountInfoDirty,
            setUpdatedSuri,
            address,
            setAddress,
            isValidating,
            setIsValidating,
            applicantId,
            setApplicantId,
            businessHomeAddress,
            setBusinessHomeaddress,
            businessHomeAddressErrors,
            setBusinessHomeaddressErrors,
            dgiFinanceInfo,
            setDgiFinanceInfo,
            dgiFinanceInfoErrors,
            setDgiFinanceInfoErrors,
            isFinanceInfoDirty,
            setIsFinanceInfoDirty,
            setIsDGIScreenFourDirty,
            isDGIScreenFourDirty,
            trustedContactInformation,
            setTrustedContactInformation,
            trustedContactInformationErrors,
            setTrustedContactInformationErrors,
            dgiEmploymentStatus,
            setDgiEmploymentStatus,
            dgiEmploymentStatusErrors,
            setDgiEmploymentStatusErrors,
            isCheckedTrustContact,
            setIsCheckedTrustContact,
            dgiAdditionalInformation,
            setDgiAdditionalInformation,
            isCheckedAdult,
            setIsCheckedAdult,
            isCheckedPoli,
            setIsCheckedPoli,
            isvalidatingpoli,
            setIsValidatingpoli,
            isTrustInfoDirty,
            setIsTrustInfoDirty,
            ismodal,
            setIsModal,
            isModalSuri,
            setIsModalSuri,
            userIds,
            setUserIds,
            userIdsErrors,
            setUserIdsErrors,
            selectedUserType,
            setSelectedUserType,
            selectedValue,
            setSelectedValue,
            edeliveryInfo,
            setEdeliveryInfo,
            codes,
            setCodes,
            codesErrors,
            setCodesErrors,
            setIsEdeliveryScreenDirty,
            isEdeliveryScreenDirty,
            onlineUserToggle,
            setOnlineUserToggle,

            msgModal,
            setMsgModal,
            addressSuriDocID,
            setAddressSuriDocID,
            ownerAddressSuriDocID,
            setOwnerAddressSuriDocID,
            addressSuriUploadStatus,
            setAddressSuriDocUploadStatus,
            ownerAddressSuriUploadStatus,
            setOwnerAddressSuriDocUploadStatus,
            addressLookupDataResponse,
            setAddressLookupDataResponse,
            addressLookupData,
            setAddressLookupData,
            countryModal,
            setCountryModal,
            vouchedFailModal,
            setVouchedFailModal,
            chks,
            setChks,
            chk,
            setChk,
            suriLang,
            setSuriLang,
            isValidSuriId,
            setIsValidSuriId,
            debitCardDisburse,
            setDebitCardDisburse,
            debitCardNotRequired,
            setDebitCardNotRequired,
            eStatementDisburse,
            setEStatementDisburse,
            eStatementNotRequired,
            setEStatementNotRequired,
            isSuriServerFail,
            setIsSuriServerFail,
            progress,
            docLabels,
            setDocLabels,
            // isClicked,
            // setIsClicked,
            uploadDocId,
            setUploadDocId,
            uploadStatus,
            setUploadStatus,
            uploadedFiles,
            setUploadedFiles,
            isDocChecklistDirty,
            setIsDocChecklistDirty,
            cancelDeed,
            setCancelDeed,
            cancelCorpRes,
            setCancelCorpRes,
            cancelCertIncorp,
            setCancelCertIncorp,
            cancelArtIncorp,
            setCancelArtIncorp,
            cancelListOff,
            setCancelListOff,
            cancelRegCer,
            setCancelRegCer,
            cancelDbaRes,
            setCancelDbaRes,
            cancelLlcRes,
            setCancelLlcRes,
            cancelCertOrg,
            setCancelCertOrg,
            cancelOpAgree,
            setCancelOpAgree,
            cancelTrustReg,
            setCancelTrustReg,
            cancelIncumbency,
            setCancelIncumbency,
            accValidationErrCode,
            setAccValidationErrCode,
            checklistErr,
            setChecklistErr,
            urlAppId,
            setUrlAppId,
            ocrResponse,
            setOcrResponse,
            setIsOcrErrMessageFound,
            isOcrErrMessageFound,
            flowInvitation,
            setFlowInvitation,
            inviFlowValues,
            setInviFlowValues,
            inviFlowValuesError,
            setInviFlowValuesError,
            inviApplicationId,
            setInviApplicationId,
            inviApplicantId,
            setInviApplicantId,
            ownership,
            setOwnership,
            signerCheck,
            setSignerCheck,
            isChked,
            setIsChked,
            isAdmin,
            setIsAdmin,
            accParticipant,
            setAccParticipant,
            accParticipantErrors,
            setAccParticipantErrors,
            expanded,
            setExpanded,
            authSigner,
            setAuthSigner,
            owner,
            setOwner,
            beneficialOwner,
            setBeneficialOwner,
            adminControl,
            setAdminControl,

            uploadGlobalStatus,
            setUploadGlobalStatus,
            uploadGlobalFiles,
            setUploadGlobalFiles,
            uploadGlobalDocId,
            setUploadGlobalDocId,
            globalProgress,
            setGlobalProgress,
            modalMessageGlobal,
            setModalMessageGlobal,
            openModalGlobal,
            setOpenModalGlobal,
            isErrorGlobal,
            setIsErrorGlobal,
            checklistErrGlobal,
            setChecklistErrGlobal,
            businessAddress,
            setBusinessAddress,
            businessOwnerAddress,
            setBusinessOwnerAddress,
            editableAddress,
            setEditableAddress,
            setProgress,
            cancelBAupload,
            setCancelBAupload,
            cancelBOAupload,
            setCancelBOAupload,
            businessAddUploadTemp,
            setBusinessAddUploadTemp,
            businessOwnerAddUploadTemp,
            setBusinessOwnerAddUploadTemp,
            flowTypeUpload,
            setFlowTypeUpload,
            flowTypeQRcode,
            setFlowTypeQRcode,
            noRadio,
            setNoRadio,
            yesRadio,
            setYesRadio,
            isCommercialProductUIDirty,
            setIsCommercialProductUIDirty,
            cancelActupload,
            setCancelActupload,
            cancelAtmupload,
            setCancelAtmupload,
            isAccordianError,
            setIsAccordianError,
            businessLegalNameInvi,
            setBusinessLegalNameInvi,
            username,
            setUserName,
            isApplicationSaved,
            setIsApplicationSaved,
            profileDetails,
            setProfileDetails,
            ocrErrorMessageArr,
            setOcrErrorMessageArr,
            addressDocLoading,
            setAddressDocLoading,
            statementUploadLoading,
            setStatementUploadLoading,
            isGlobalFileUploadingAll,
            setIsGlobalFileUploadingAll,
            tokenTimerInterval,
            setTokenTimerInterval,
            businessOwnerManualInfo,
            setBusinessOwnerManualInfo,
            isSuccessRespFound,
            setIsSuccessRespFound,
            countries,
            setCountries,
            receivedCountryData,
            setReceivedCountryData,
            countryList,
            setCountryList,
            enterCountry,
            setEnterCountry,
            tabKey,
            setTabKey,
            internaltionalWireTransferError,
            setInternationalWiretransferError,
            isQrCodeDirty,
            setIsQrCodeDirty,
            qrCodeVal,
            setQrCodeVal,
            qrCodeValError,
            setQrCodeValError,
            etbLogin,
            setEtbLogin,
            isLogin,
            setIsLogin,
            isBackClicked,
            setIsBackClicked,
            isQR,
            setIsQR,
            isSuriSearchChanged,
            setIsSuriSearchChanged,
            cancelAct22upload,
            setCancelAct22upload,
            disable,
            setDisable,
            salesAdvisor,
            setSalesAdvisor,
            organization,
            setOrganization,
            coSigner,
            setCoSigner,
            conEmploymentStatus,
            setConEmploymentStatus,
            conEmploymentStatusError,
            setConEmploymentStatusError,
            addIncome,
            setAddIncome,
            isDebitSettled,
            setIsDebitSettled,
            isDebitNotSettled,
            setIsDebitNotSettled,
            newAddress,
            setNewAddress,
            addressWithErrors,
            setAddressWithErrors,
            popupModalIntial,
            setPopupModalIntial,
            addressEditQuestionModal,
            setAddressEditQuestionModal,
            saveAppContactInfo,
            setSaveAppContactInfo,
            saveAppContactInfoErrors,
            setSaveAppContactInfoErrors,
            saveAppContactScreenDirty,
            setSaveAppContactScreenDirty,
            isModalSaveLater,
            setIsModalSaveLater,
            savelaterVal,
            setSaveLaterVal,
            isModalSave,
            setIsModalSave,
            isHandleConti,
            setIsHandleConti,
            vouchLastName,
            setVouchLastName,
            flowTypeVerifyScreen,
            setFlowTypeVerifyScreen,
            flowTypePlaidVerifyScreen,
            setFlowTypePlaidVerifyScreen,
            plaidVerification,
            setPlaidVerification,
            plaidVerificationErrors,
            setPlaidVerificationErrors,
            returnTosaveApp,
            setReturnTosaveApp,
            returnTosaveAppErrors,
            setReturnTosaveAppErrors,
            returnTosaveAppScreenDirty,
            setReturnTosaveAppScreenDirty,
            continueModal,
            setContinueModal,
            savedDataFlow,
            setSavedDataFlow,
            savedItemsAnswer,
            setSavedItemsAnswer,
            savedFormAnswer,
            setSavedFormAnswer,
            automatedAccDetails,
            setAutomatedAccDetails,
            vouchdob,
            setVouchdob,
            userSavedPage,
            setUserSavedPage,
            isValidatingVouch,
            setIsValidatingVouch,
            isDuplicate,
            setIsDuplicate,
            userOptionAddress,
            setUserOptionAddress,
            idError,
            setIdError,
            attemptLeftInOptionSelect,
            setAttemptLeftInOptionSelect,
            loginEmail,
            setLoginEmail,
            otpDataEmail,
            setOtpDataEmail,
            deliveryMedium,
            setDeliveryMedium,
            customerVerifyToken,
            setCustomerVerifyToken,
            customerNumber,
            setCustomerNumber,
            detailAcc,
            setDetailAcc,
            errorMessage,
            setErrorMessage,
            state,
            setState,
            emailList,
            setEmailList,
            customerGuid,
            setCustomerGuid,
            customerPhone,
            setCustomerPhone,
            loginAttemptCounter,
            setLoginAttemptCounter,
            customerName,
            setCustomerName,
            phoneList,
            setPhoneList,
            lightAuthToken,
            setLightAuthToken,
            contactInfoDetails,
            setContactInfoDetails,
            receiveCodeState,
            setReceiveCodeState,
            otpToken,
            setOtpToken,
            loadingDetails,
            setLoadingDetails,
            initialPayLoading,
            setInitialPayLoading,

            initialCallFlag,
            setInitialCallFlag,
            flowTypeETB,
            setFlowTypeETB,
            languageETBflow,
            setLanguageETBflow,
            loginHomeAddress,
            setLoginHomeAddress,
            loginHomeAddressErrors,
            setLoginHomeAddressErrors,
            customerAppId,
            setCustomerAppId,
            loginDetails,
            setLoginDetails,
            selectedAddress,
            setSelectedAddress,
            loginDetailsErrors,
            setLoginDetailsErrors,
            isEtbAddressDirty,
            setIsEtbAddressDirty,
            isNewAddress,
            setIsNewAddress,
            reFlag,
            setReFlag,
            sessionCallFlag,
            setSessionCallFlag,
            sessionCallFlagConsent,
            setSessionCallFlagConsent,
            cancelEmancipationEvidence,
            setCancelEmancipationEvidence,
            personalInfoConsumer,
            setPersonalInfoConsumer,
            personalInfoConsumerErrors,
            setPersonalInfoConsumerErrors,
            yesRadioPersonal,
            setYesRadioPersonal,
            noRadioPersonal,
            setNoRadioPersonal,
            yesRadioPersonalTrust,
            setYesRadioPersonalTrust,
            noRadioPersonalTrust,
            setNoRadioPersonalTrust,
            depositeCardDisburse,
            setDepositeCardDisburse,
            isCheckedPoliConsumer,
            setIsCheckedPoliConsumer,
            suriSaveFlag,
            setSuriSaveFlag,
            savedSuriDocs,
            setSavedSuriDocs,
            editableAddressError,
            setEditableAddressError,
            savedChecklistDocs,
            setSavedChecklistDocs,
            accInfoAddToggleDisable,
            setAccInfoAddToggleDisable,
            savedBusinessDocs,
            setSavedBusinessDocs,
            callLookupApi,
            setCallLookupApi,
            callLookupApiHome,
            setCallLookupApiHome,
            callLookupApiEmp,
            setCallLookupApiEmp,
            callLookupApiHomeSelfEmp,
            setCallLookupApiHomeSelfEmp,
            depositeCardDisburseFeature,
            setDepositeCardDisburseFeature,
            debitCardDisburseFeature,
            setDebitCardDisburseFeature,
            excessTransferFeature,
            setExcessTransferFeature,
            excessAmt,
            setExcessAmt,
            excessAmtError,
            setExcessAmtError,
            isFeatureScreenDirty,
            setIsFeatureScreenDirty,
            accNoListForOrientalAccount,
            setAccNoListForOrientalAccount,
            plaidDetails,
            setPlaidDetails,
            plaidAccNoLinked,
            setPlaidAccNoLinked,

            plaidSuccessDetails,
            setPlaidSuccessDetails,
            selectedOrientalAccountProgress,
            setSelectedOrientalAccountProgress,
            plaidLinkTokenObj,
            setPlaidLinkTokenObj,
            callPlaidLink,
            setCallPlaidLink,
            plaidRetry,
            setPlaidRetry,
            tokenVal,
            setTokenVal,
            isBalanceValidationFail,
            setIsBalanceValidationFail,
            statementFileName,
            setStatementFileName,
            statementError,
            setStatementError,
            singleAccDis,
            setSingleAccDis,
            microVerifyError,
            setMicroVerifyError,
            microPlaidPopUp,
            setMicroPlaidPopUp,
            plaidStatementMandatoryError,
            setPlaidStatementMandatoryError,
            isFundingRequired,
            setIsFundingRequired,
            vouchedJob,
            setVouchedJob,
            vouchedResponseAddr,
            setVouchedResponseAddr,
            disableContinue,
            setDisableContinue,
            crossCheckResponse,
            setCrossCheckResponse,
            msgPopUp,
            setMsgPopUp,
            vouchedImage,
            setVouchedImage,
          }}
        >
          {currentPage !== 'vouched' &&
          currentPage !== 'camera-upload' &&
          currentPage !== 'verify-plaid-micro' ? (
            <MainHeader setLanguage={setWebPageLanguage} firstName={firstName} />
          ) : (
            ''
          )}
          <ErrorBoundary
            // eslint-disable-next-line react/no-unstable-nested-components
            FallbackComponent={({ error, resetErrorBoundary }) => (
              <SubmissionFailPage
                isErrorBoundary
                error={error}
                resetErrorBoundary={resetErrorBoundary}
              />
            )}
          >
            <Routing />
          </ErrorBoundary>
          {currentPage !== 'vouched' && currentPage !== 'camera-upload' ? <Footer /> : ''}
          <InactivityModal
            resetTimer={reset}
            setOpenInactivityModal={setOpenInactivityModal}
            openModal={openInactivityModal}
          />
          <SessionTimeoutModal
            startTimer={startTimer}
            pingTimer={pingTimer}
            setOpenSessionTimeoutModal={setOpenSessionTimeoutModal}
            openModal={openSessionTimeoutModal}
          />
        </LeadGenerationProvider>
      </MuiThemeProvider>
    </div>
  );
};

export default withTranslation()(App);

App.propTypes = {
  i18n: PropTypes.object,
};
