/* eslint-disable max-lines */
// English Translations
export const engTranslation = {
  'Your Elite account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our':
    'Your Elite account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our',
  'We cannot complete your application.': 'We cannot complete your application.',
  'You are currently not eligible to open this account due to information on your profile.':
    'You are currently not eligible to open this account due to information on your profile.',
  'Please provide additional details': 'Please provide additional details',
  'Do you have an Active SURI Merchant Number?': 'Do you have an Active SURI Merchant Number?',
  '(Upload address proof)': '(Upload address proof)',
  'Oops we hit a snag!': 'Oops we hit a snag!',
  'Enter your SURI Merchant Number details and search:':
    'Enter your SURI Merchant Number details and search:',
  'Use the edit icon to provide the correct address information. Upload address proof!':
    'Use the edit icon to provide the correct address information. Upload address proof!',
  'An active SURI Merchant Number is required to continue.':
    'An active SURI Merchant Number is required to continue.',
  'Enter New Address': 'Enter New Address',
  'For You': 'For You',
  Hello: 'Hello',
  Guest: 'Guest',
  'First Name': 'First Name',
  'Middle Initial': 'Middle Initial',
  'Last Name': 'Last Name',
  'Second Last Name': 'Second Last Name',
  'Alternate Phone': 'Alternate Phone',
  Gender: 'Gender',
  'Date of Birth': 'Date of Birth',
  'date of birth': 'date of birth',
  'Date of birth': 'Date of birth',
  'last name': 'last name',
  ' and ': ' and ',
  'Your ': 'Your ',
  ' has been updated as per your uploaded Id.': ' has been updated as per your uploaded Id.',
  'Email Address': 'Email Address',
  'Social Security Number': 'Social Security Number',
  'Social security number': 'Social security number',
  'Enter first name': 'Enter first name',
  'Enter First Name': 'Enter First Name',
  'Enter middle initial': 'Enter middle initial',
  'Enter Middle Initial': 'Enter Middle Initial',
  'Enter last name': 'Enter last name',
  'Enter Last Name': 'Enter Last Name',
  'Enter second last name': 'Enter second last name',
  'Enter Second Last Name': 'Enter Second Last Name',
  'Enter phone number': 'Enter phone number',
  'Enter Phone Number': 'Enter Phone Number',
  'Enter email address': 'Enter email address',
  'Enter Email Address': 'Enter Email Address',
  Male: 'Male',
  Female: 'Female',
  '* Invalid Format': '* Invalid Format',
  '* Mandatory Field': '* Mandatory Field',
  Back: 'Back',
  Continue: 'Continue',
  'Home Address': 'Home Address',
  'Address Line 1': 'Address Line 1',
  'Address Line 2': 'Address Line 2',
  State: 'State',
  City: 'City',
  'Zip Code': 'Zip Code',
  'Mailing address same as home address': 'Mailing address same as home address',
  'Enter address 1': 'Enter address 1',
  'Enter address 2': 'Enter address 2',
  Unmarried: 'Unmarried',
  'Enter Zip Code': 'Enter Zip Code',
  Married: 'Married',

  'Marital Status': 'Marital Status',
  Submit: 'Submit',
  'Thank you for choosing Oriental': 'Thank you for choosing Oriental',
  'Your information was sent successfully. We will contact you in the next 24 to 48 hours.':
    'Your information was sent successfully. We will contact you in the next 24 to 48 hours.',
  'Your information was sent successfully. We will contact you in the next 24 hours to complete the account opening process.':
    'Your information was sent successfully. We will contact you in the next 24 hours to complete the account opening process.',
  'Your information was sent successfully. We will contact you in the next 48 hours to complete the account opening process.':
    'Your information was sent successfully. We will contact you in the next 48 hours to complete the account opening process.',
  'Return to orientalbank.com': 'Return to orientalbank.com',
  YYYY: 'YYYY',
  DD: 'DD',
  MMM: 'MMM',
  'Enter Unit Number and Street': 'Enter Unit Number and Street',
  '* Zipcode should have 5 digits': '* Zipcode should have 5 digits',
  '* Area Code is Mandatory': '* Area Code is Mandatory',
  '* Area Code should have 3 digits': '* Area Code should have 3 digits',
  USA: 'USA',
  VI: 'VI',
  'Libre Account': 'Libre Account',
  'Cuenta Libre': 'Libre Account',
  // 'Progresa Account': 'Progresa Account',
  // 'Cuenta Progresa': 'Progresa Account',
  'Ahorra Account': 'Ahorra Account',
  'Cuenta Ahorra': 'Ahorra Account',
  'ABC Account': 'ABC Account',
  'Cuenta ABC': 'ABC Account',
  'Ahorro Club Account': 'Ahorro Club Account',
  'Cuenta Ahorro Club': 'Ahorro Club Account',
  'Certificate of Deposit': 'Certificate of Deposit',
  'Certificado de Deposito': 'Certificate of Deposit',
  'DGI Balanced Fund IRA': 'DGI Balanced Fund IRA',
  'CD IRA': 'CD IRA',
  'IRA Facil': 'IRA Facil',
  'IRA Fácil': 'IRA Facil',
  'Educational IRA': 'Educational IRA',
  'IRA Educativa': 'Educational IRA',
  'Annuity IRA': 'Annuity IRA',
  'DGI Contribution': 'DGI Contribution',
  'DGI Aportaciones': 'DGI Contribution',
  'Enter social security number': 'Enter social security number',
  '* You must be 18 years or older to open this account':
    '* You must be 18 years or older to open this account',
  '* You must be 21 years or older to open this account':
    '* You must be 21 years or older to open this account',
  '* Social Security Number should have 9 digits': '* Social Security Number should have 9 digits',
  Upload: 'Upload',
  'Thank you': 'Thank you for providing your information.',
  Instructions:
    "Please provide a picture of your driver's license or passport. Follow the instructions below to continue the verification process on your phone. <br/> <br/>1. Open the Camera App or QR Reader on your phone<br/> 2. Hold the device so the QR Code appears in the viewfinder <br/> 3. Click on the notifications to open the verification link<br/><br/>",
  IRA: 'IRA',
  Footer: 'Oriental. All Rights Reserved. FDIC Member.',
  'Oriental. All Rights Reserved.': 'Oriental. All Rights Reserved.',
  '* Unknown Email': '* Unknown Email',
  '* Email Syntax Error': '* Email Syntax Error',
  '* Email Domain Not Found': '* Email Domain Not Found',
  '* Email Server Not Found': '* Email Server Not Found',
  '* Invalid Email': '* Invalid Email',
  '* Invalid Domain': '* Invalid Domain',
  '* Unavailable Domain': '* Unavailable Domain',
  'Aria Label Preferred Contact Time':
    'Preferred Contact Time Select Preferred Contact Time dropdown menu button',
  'Aria Label Gender': 'Gender Required Select Gender dropdown menu button',
  'Aria Label DOB day': 'Date Of Birth Required Select Day in Date Of Birth dropdown Required',
  'Aria Label DOB Month': 'Select Month in Date Of Birth dropdown Required',
  'Aria Label DOB Year': 'Select Year in Date Of Birth dropdown Required',
  'Aria Label Country': 'Country Required Choose your country dropdown menu button',
  'Aria Label State': 'State Required Choose your state dropdown menu button',
  'Aria Label City': 'City Required Choose your city dropdown menu button',
  Required: 'Required',
  PreviewConsent:
    'Sensitive information will be encrypted, registered and safeguarded in our systems. You must provide a valid and unexpired primary ID to confirm your information and identity. Accepted ID are: driver’s license, passport and passport card.*Digital ID screenshots are not allowed.',
  lowerIdInstructions: 'Please take a photo of your ID now',
  lowerFaceInstructions: "Please use a current, clear picture of yourself that doesn't include:",
  upperFailure: 'Try Again',
  verifyFail: 'Sorry for the inconvenience',
  cameraButton: 'Take a Photo',
  'Provide Consent': 'Provide Consent',
  FrontId: 'ID',
  Face: 'Selfie',
  Done: 'Done',
  DoneId: 'Finished',
  'Enter address details': 'Enter address details',
  'Enter Home Address Details': 'Enter Home Address Details',
  DefaultCountryPR: 'You must be a Puerto Rico resident to open this account.',
  DefaultCountryVI: 'You must be a Virgin Islands resident to open this account.',
  surveyContinue: 'Continue',
  'Upload Home Address Proof': 'Upload Home Address Proof',
  Select: 'Select',
  'Select ': 'Select ',
  'Your Account': 'Your Account',
  'Your Information': 'Your Information',
  'Upload ID or Passport': 'Upload ID or Passport',
  '* Mobile number should have 7 digits': '* Mobile number should have 7 digits',
  'Error Try Again': 'Error Try Again',
  'Date Picker': 'Date Picker',
  'Select...': 'Select...',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  ProductModalTextOne: 'We are not able to complete your application.',
  ProductModalTextTwo:
    'As per Oriental Bank policy we are currently unable to establish a relationship with politically exposed persons, their family members and their close associates.',
  'Upload Certification': 'Upload Certification',
  'Account Opening Text DGICont':
    'Our online form helps us get to know you and your needs. Once completed, we will contact you to complete the request.',

  'Account Opening Text':
    'Our online form helps us get to know you and your needs. Once completed, we will contact you to verify your identity and open your account.',
  'Let’s start!': 'Let’s start!',
  'Where can we contact you?': 'Where can we contact you?',
  Mobile: 'Mobile',
  'What is your home address?': 'What is your home address?',
  'Do you have a different mailing address?': 'Do you have a different mailing address?',
  ConsentHelperText:
    'Please read the following disclosures and account documents which provide important information related to your account.',
  ConsentHelperTextProg:
    'Before we engage in this transaction, you must understand your rights and responsibilities and provide your consent.',
  'Employment Status': 'Employment Status',
  'Employment Status Prog': 'Employment Status',
  'Select Employment Status': 'Select Employment Status',
  Employed: 'Employed',
  'Not Employed': 'Not Employed',
  Retired: 'Retired',
  Employer: 'Employer',
  EmployerProg: 'Employer',
  Occupation: 'Occupation',
  OccupationProg: 'Occupation',
  'Annual Income': 'Annual Income',
  'Select Annual Income': 'Select Annual Income',
  'Less than': 'Less than',
  'or More': 'or More',
  Reason: 'Reason',
  Student: 'Student',
  'No Previous Employer': 'No Previous Employer',
  'Not Currently Employed': 'Not Currently Employed',
  'Previous Employer': 'Previous Employer',
  'Enter Previous Employer': 'Enter Previous Employer',
  'Source of Current Income': 'Source of Current Income',
  'Enter Source of Current Income': 'Enter Source of Current Income',
  'Your Consent': 'Your Consent',
  'Interest Withholding Setup': 'Interest Withholding Setup',
  'Interest Withholding Setup Prog': 'Interest Withholding Setup',
  WithholdText:
    'Do you wish Oriental Bank to withhold interest for this account? If so select the option included below.',
  WithholdTextProg:
    'Do you wish Oriental Bank to withhold interest for this account? If so, select the option included below.',
  'Otherwise, please DO NOT check below': 'Otherwise, please DO NOT check below:',
  'What is your Employment Status?': 'What is your Employment Status?',
  'School Name': 'School Name',
  'Enter School Name': 'Enter School Name',
  'Enter your occupation details': 'Enter your occupation details',
  '* Invalid Commercial Customer': '* Invalid Commercial Customer',
  '* Invalid address': '* Invalid address',
  'Please Select': 'Please Select',
  'Please select a reason': 'Please select a reason',
  Close: 'Close',
  '* Invalid Employer Name': '* Invalid Employer Name',
  '* Invalid School Name': '* Invalid School Name',
  '* Invalid Previous Employer': '* Invalid Previous Employer',
  '* Invalid Source of Current Income': '* Invalid Source of Current Income',
  'Sorry we hit a snag. Please try submitting your application later.':
    'Sorry we hit a snag. Please try submitting your application later.',
  'Mailing Address Tooltip':
    'Address where you will receive your debit card and other mail from the bank.',
  'Question Header Tooltip': 'Do not select if not applicable for you',
  'Politically Exposed Tooltip': `The term "politically exposed person" generally includes a current or former senior foreign political figure, their immediate family, and their close associates.`,
  'Act22 Tooltip': `Act No. 22 of 2012, The "Individual Investors Act" provides tax exemptions to eligible individuals residing in Puerto Rico. The benefits of Act No. 22 are only available to bona-fide residents of Puerto Rico that were not bona-fide residents of Puerto Rico for the 6-years period preceding the enactment of the Act on January 12, 2012.`,
  'More Info': 'More Info',
  Uploading: 'Uploading',
  'Employment Status Aria Label':
    'Employment Status Required Please Select Employment Status dropdown menu button',
  'Annual Income Aria Label':
    'Annual Income Required Please Select Annual Income dropdown menu button',
  'Reason Aria Label': 'Reason Required Please Select Reason dropdown menu button',
  'Change Language': 'Change Language',
  'Required Select dropdown menu button': 'Required Select dropdown menu button',
  'ACHTransaction Tooltip':
    'Includes payroll deposits, pensions, social security benefits, etc., as well as direct payments such as auto loan and mortgage payments.',
  'Mailing Address Info': 'Mailing Address Info',
  upperIdInstructions: `<span class='vouched_idText' style="margin:0">Please provide a picture of a current (non-expired) government issued ID </span><span class='vouched_idText' style="margin:0">(e.g., driver's license, passport).</span>`,
  retryMessage: 'We could not recognize the ID',
  'Your session has timed out.': 'Your session has timed out.',
  SessionModalTextLineTwo:
    'To protect your personal information, we clear what you entered after 15 minutes of inactivity. Restart your online application anytime.',
  'Restart my application': 'Restart my application',
  InactivityTexLineOne:
    'Your session is about to expire in 2 minutes due to inactivity. Do you wish to continue with your application?',
  qrDesktopInstructions: 'You can {qrDesktopLink} if you have a good computer camera.',
  qrDesktopLink: 'finish the verification on your desktop',
  cameraFaceButton: 'Take a Photo',
  lowestFaceInstructions: `<span class="vouched_lowestFaceInstructions"><div style="max-width: 400px; margin-bottom: 10px" class="vouched_faceText"><ul style="text-align: left; margin: 0 30px; padding: 0"><li class="vouched_faceText">Sunglasses, hats or other facial obstructions</li><li class="vouched_faceText">Other people (e.g., a family photo)</li><li class="vouched_faceText">Pictures of a picture</li></ul></div></span>`,
  '* Mobile number should have 10 digits': '* Mobile number should have 10 digits',
  '* Alternate phone number should not be same as mobile number':
    '* Alternate phone number should not be same as mobile number',
  'Country/Region': 'Country/Region',
  'Click to upload again.': 'Click to upload again.',
  'Loading ...': 'Loading ...',
  SelfieCameraButton: 'Upload from Photo Library',
  PreviewAddressConsent:
    'Address proof is required to open your account if your current home address is not available on your ID. You will need to provide a utility bill with your name and address issued within the last 60 days.',
  Vouching: 'Verifying',
  'Address Description Text':
    'Validating your address is an important step in identity confirmation. Review the information below and upload proof of address if your current home address is different from the address on your ID.',
  'Address Description Text Prog':
    'Validating your address is an important step in identity confirmation. Review the information below and upload proof of address if your current home address is different from the address on your ID.',
  'Personal Info Text':
    "Upload your driver's license or passport first and we will complete the form using your information.",
  'Personal Info Text Prog':
    "Upload your driver's license or passport first and we will complete the form using your information.",
  'Id Description Text': 'Save Time! Upload your ID to skip manually completing your information.',
  'ID description Sub Text': 'Your information will be encrypted and safeguarded in our system.',
  'More about you!': 'More about you!',
  'More About You': 'More About You',
  'Preferred Contact Time': 'Preferred Contact Time',
  'Preferred Time': 'Preferred Time',
  '8:00 AM to 12:00 PM': `8:00 AM to 12:00 PM`,
  '12:00 PM to 6:00 PM': '12:00 PM to 6:00 PM',
  ContactDisclaimer:
    'By selecting continue, you authorize us to communicate with you through calls, text messages and emails.',
  '* Valid Phone': '* Valid Phone',
  '* 7-Digit Match': '* 7-Digit Match',
  '* Corrected Area Code': '* Corrected Area Code',
  '* Updated Area Code': '* Updated Area Code',
  '* Cellular Line': '* Cellular Line',
  '* Land Line': '* Land Line',
  '* VOIP Line': '* VOIP Line',
  '* Residential Number': '* Residential Number',
  '* Business Number': '* Business Number',
  '* SOHO Number': '* SOHO Number',
  '* Invalid Phone': '* Invalid Phone',
  '* Blank Phone': '* Blank Phone',
  '* Bad Phone': '* Bad Phone',
  '* Multiple Match': '* Multiple Match',
  '* Bad Prefix/Prefix +1': '* Bad Prefix/Prefix +1',
  '* Please enter a valid phone number': '* Please enter a valid phone number',
  '* Wrong country code': '* Wrong country code',
  'Self Employed': 'Self Employed',
  'Name of your Business': 'Name of your Business',
  'Enter Employer Name': 'Enter Employer Name',
  'Compliance Page Text':
    'Before we can open your account, there is additional information about you we are required to know.',
  'Compliance Page Text Prog':
    'Before we can open your account, there is additional information about you we are required to ask.',
  'Product Page Text':
    'Please review the questions below which apply to your selected account and help us understand your intended use. Answer only if applicable.',
  'Product Page Text Prog':
    'Please review the questions below which apply to your selected account and help us understand your intended use. Answer only if applicable.',
  'Expired ID': '* Expired ID',
  'Invalid ID': '* Invalid ID',
  'Funding Screen Description':
    'Choose how you prefer to fund your account. We will process your deposit in 24-72 hours.',
  'Funding Screen Description Prog':
    'Use the following menus to choose how you prefer to fund your account.',
  'How would you like to make your first deposit ?':
    'How would you like to make your first deposit ?',
  'Deposit Amount': 'Deposit Amount',
  'Type of Account': 'Type of Account',
  'Account Number': 'Account Number',
  'Enter account number': 'Enter account number',
  Bank: 'Bank',
  'Transit Number': 'Transit Number',
  'Enter Route & Transit Number': 'Enter Route & Transit Number',
  'Oriental Account': 'Oriental Account',
  'Another Bank Account': 'Another Bank Account',
  'Aria Label Type Of Account': 'Type Of Account Select Type Of Account dropdown menu button',
  'Aria Label Account Number': 'Account Number Select Type Of Account Number dropdown menu button',
  'Aria Label Bank': 'Bank Select Bank dropdown menu button',
  'Card Number': 'Card Number',
  'Enter card number': 'Enter card number',
  '* Minimum deposit required is $': '* Minimum deposit required is $',
  'Debit Card': 'Debit Card',
  'Account transfer or check': 'Account transfer or check',
  'Expiry Date': 'Expiry Date',
  'Upload a void check or Bank Statement in order to confirm your account.':
    'Upload a void check or Bank Statement in order to confirm your account.',
  'Aria Label Expiry Month': 'Select Expiry Month in Expiry Date dropdown Required',
  'Aria Label Expiry Year': 'Select Expiry Year in Expiry Date dropdown Required',
  Download: 'Download All',
  PreviewStatement:
    'Sensitive Information will be encrypted and safeguard in our systems. A blank Void check or bank statement from the last 60 days is required to confirm your account.',
  Checking: 'Checking',
  Savings: 'Savings',
  'Aria Label Transit': 'Transit Number Required Select Transit Number dropdown menu button',
  Other: 'Other',
  'Bank Name': 'Bank Name',
  'Enter Bank name': 'Enter Bank name',
  'Enter deposit amount. Minimum': 'Enter deposit amount. Minimum',
  // commercial
  'Business Account Opening': 'Let’s start with your contact information.',
  'Personal/Business Information': 'Personal/Business Information',
  'Your Account/Business': 'Your Account/Business',
  'We’d love to hear about you and your business!':
    'We’d love to hear about you and your business!',
  BusinessContactDisclaimer:
    'By providing this information, you are giving your consent for Oriental Bank to communicate with you through call, text messages and email.',
  'Business Legal Name': 'Business Legal Name',
  'SURI Merchant Number': 'SURI Merchant Number',
  'State Department Registry #': 'State Department Registry #',
  'Enter Business Legal Name': 'Enter Business Legal Name',
  'Enter Merchant Number': 'Enter Merchant Number',
  'Enter State Department Registry': 'Enter State Department Registry',
  '* SURI Merchant Number should have 11 digits': '* SURI Merchant Number should have 11 digits',
  '* State Department Registry # should have min 4 and max 20 digits':
    '* State Department Registry # should have min 4 and max 20 digits',
  'DBA type accounts can only be opened by the owner !':
    'DBA type accounts can only be opened by the owner !',
  '* Business Tax ID should have 9 digits': '* Business Tax ID should have 9 digits',
  'Entity Type': 'Entity Type',
  DBA: 'DBA',
  Corporation: 'Corporation',
  'Corporation DBA': 'Corporation DBA',
  LLC: 'LLC',
  Partnership: 'Partnership',
  Trust: 'Trust',
  'Relationship with the Company': 'Relationship with the Company',
  Owner: 'Owner',
  Employee: 'Employee',
  'Board Member Secretary': 'Board Member Secretary',
  'Board Member President': 'Board Member President',
  Sales: 'Sales',
  'Less than $ 500,000': 'Less than $ 500,000',
  '$ 500,000 to $ 1 Million': '$ 500,000 to $ 1 Million',
  'Over $ 5 Million': 'Over $ 5 Million',
  'N/A': 'N/A',
  'Net Worth': 'Net Worth',
  'Select Net Worth': 'Select Net Worth',
  'Less than $ 50,000': 'Less than $ 50,000',
  '$ 50,000 - $ 100,000': '$ 50,000 - $ 100,000',
  '$ 100,000 - $ 250,000': '$ 100,000 - $ 250,000',
  '$ 250,000 - $ 500,000': '$ 250,000 - $ 500,000',
  '$ 500,000 - $ 1 Million': '$ 500,000 - $ 1 Million',
  '$ 1 Million to $ 5 Million': '$ 1 Million to $ 5 Million',
  'More than $ 1 Million': 'More than $ 1 Million',
  'Number of Employees': 'Number of Employees',
  '1 - 10': '1 - 10',
  '11 - 50': '11 - 50',
  '51 - 100': '51 - 100',
  '101 - 500': '101 - 500',
  'More than 500': 'More than 500',

  // compliancee
  'Understanding your business type is an important step to get started.':
    'Understanding your business type is an important step to get started.',
  'Is your company involved in any of the businesses listed below?':
    'Is your company involved in any of the businesses listed below?',
  'My company is one of the business listed above':
    'My company is one of the business listed above',
  'I certify that my company is not involved in any of these listed 19 businesses.':
    'I certify that my company is not involved in any of these listed 19 businesses.',
  // item
  BusinessProductModalTextOne: 'We are not able to complete your application.',
  BusinessProductModalTextOneCommercial: 'We are not able to complete your application.',
  'As per Oriental Bank policy we are currently unable to establish a relationship without an active Merchant Number.':
    'As per Oriental Bank policy we are currently unable to establish a relationship without an active Merchant Number.',

  'As per Oriental Bank policy we are currently unable to establish a relationship due to the selected business type.':
    'As per Oriental Bank policy we are currently unable to establish a relationship due to the selected business type.',
  'We currently do not offer bank products for the selected business type.':
    'We currently do not offer bank products for the selected business type.',
  // additional
  'All fields are required to verify your information and to comply with federal regulations.':
    'All fields are required to verify your information and to comply with federal regulations.',
  'More about your business': 'More about your business',

  'Business Tax ID': 'Business Tax ID',
  'Enter Business Tax ID': 'Enter Business Tax ID',
  'Enter website address': 'Enter website address',
  Website: 'Website',
  'Business Address': 'Business Address',
  'Enter Business Address': 'Enter Business Address',
  'Business Owner Address': 'Business Owner Address',
  'Enter Business Owner Address': 'Enter Business Owner Address',
  'Business Name': 'Business Name',
  'Enter Business Name': 'Enter Business Name',
  'Business Owner Name': 'Business Owner Name',
  'Enter Business Owner Name': 'Enter Business Owner Name',
  'Please enter a valid SURI  Merchant Number': 'Please enter a valid SURI Merchant Number',
  'I certify that the above information is correct.':
    'I certify that the above information is correct.',
  'Detalles de certificado': 'Detalles de certificado',
  'Enter mobile number': 'Enter mobile number',
  'Detalles de comerciante': 'Detalles de comerciante',
  'Detalles de la localidad': 'Detalles de la localidad',
  'Is the information for your business correct?': 'Is the information for your business correct?',
  'Confirm Your Business Information': 'Confirm Your Business Information',
  'We have obtained your business information from SURI; please confirm this is your business:':
    'We have obtained your business information from SURI; please confirm this is your business:',
  'Enter SURI Merchant Number': 'Enter SURI Merchant Number',
  NotAbleToFetchSuriEnterAgain:
    'We were unable to find your Merchant Number and details. Please enter your Merchant Number below:',
  WhatInfoIsIncorrect: 'Please let us know what information is incorrect:',
  'Do you have an active Merchant Number ?': 'Do you have an active Merchant Number ?',
  'Annual Sales': 'Annual Sales',
  'Please confirm if the information obtained from SURI is correct.':
    'Please confirm if the information obtained from SURI is correct.',
  'Let’s confirm your business information. We will use your SURI Merchant number for less data entry.':
    'Let’s confirm your business information. We will use your SURI Merchant number for less data entry.',
  'Confirm your SURI information': 'Confirm your SURI information',
  OwnerTrans: 'Owner',

  'Business Owner Information': 'Business Owner Information',
  '* The ownership percentage should have a value between 1 and 100.':
    '* The ownership percentage should have a value between 1 and 100.',
  OwnershipPercentToolTip: 'Ownership Percentage should be between 1 to 100.',
  'Will you be a signer on this account ?': 'Will you be a signer on this account ?',
  'Ownership Percentage': 'Ownership Percentage',
  'Is the address Provided in the ID proof your home address ?':
    'Is the address Provided in the ID proof your home address ?',
  InternetGamblingModalText:
    'As per Oriental Bank policy we are currently unable to establish a relationship with businesses which participate in internet gambling.',
  'Fast Cash Services': 'Fast Cash Services',
  'Bulk Cash Services': 'Bulk Cash Services',
  'Internet Gambling': 'Internet Gambling',
  "Private ATM's": `Private ATM's`,
  ' Act No. 20': ' Act No. 20',
  'Is the company engaged in any of the following?':
    'Is the company engaged in any of the following?',
  Act20Cert: '(Upload your Act 20 Certification)',
  ATMRental: '(Upload ATM Rental or Purchase Documentation)',
  'Aria Label Relationship with Company':
    'Relationship with the Company Required Select Relationship with the Company dropdown menu button',
  'Enter business information below:': 'Enter business information below:',
  '* Private ATM document is required': '* Private ATM document is required',
  '* Act 20 Certification document is required': '* Act 20 Certification document is required',
  NetWorthTooltip:
    'Net Worth is the total amount of your assets minus your liabilities(excluding home).',
  TaxBracketTooltip: 'Tax Bracket is the range of your income taxed as a given rate.',
  LiquidNetWorthTooltip:
    'Liquid Net Worth is the amount of money you’ve got in cash or cash equivalents after you deducted your liabilities from your liquid assets.',
  DGIModalText:
    'As per Oriental Bank policy we are currently unable to establish a relationship with politically exposed persons, their family members and their close associates.',

  DGIAdultTooltip:
    'Specified Adult shall mean a natural person aged 65 and older; or a natural person aged 21 and older who the member reasonably believes has mental or physical impairment that renders the individual unable to protect his or her own interests.',
  'Are you a Specified Adult?': 'Are you a Specified Adult?',
  'Do you want to provide information for a Trusted Contact?':
    'Do you want to provide information for a Trusted Contact?',
  'Employment Years': 'Employment Years',
  'Your Financial Information': 'Your Financial Information',
  Networth: 'Networth',
  'Tax Bracket': 'Tax Bracket',
  'Help us understand your General Investment Knowledge':
    'Help us understand your General Investment Knowledge',
  'What is your investment knowledge?': 'What is your investment knowledge?',
  'Do you have investment experience in Equities?':
    'Do you have investment experience in Equities?',
  'Do you have investment experience in Fixed Income?':
    'Do you have investment experience in Fixed Income?',
  'Do you have investment experience in Mutual Funds?':
    'Do you have investment experience in Mutual Funds?',
  'What is your risk tolerance?': 'What is your risk tolerance?',
  'What is your investment objective?': 'What is your investment objective?',
  'What are your Liquidity Needs?': 'What are your Liquidity Needs?',
  'How long do you plan to hold the asset before selling it?':
    'How long do you plan to hold the asset before selling it?',
  'Less than $30,000': 'Less than $30,000',
  AssetSellingTooltip: 'How long do you plan to hold the asset before selling it',
  '$200,000 or More': '$200,000 or More',
  '10 or more': '10 or more',
  None: 'None',
  Limited: 'Limited',
  Moderate: 'Moderate',
  Extensive: 'Extensive',
  'High Risk': 'High Risk',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  'Capital Appreciation': 'Capital Appreciation',
  Income: 'Income',
  'Preservation of Capital': 'Preservation of Capital',
  'Trading Profits': 'Trading Profits',
  'Before we can open your account there is additional information about you we are required to know.':
    'Before we can open your account there is additional information about you we are required to know.',
  'Some questions we are required to ask:': 'Some questions we are required to ask:',
  'Some questions Prog': 'Some questions we are required to ask:',
  'Are you a Politically Exposed Person or are you related to a Politically Exposed Person?':
    'Are you a Politically Exposed Person or are you related to a Politically Exposed Person?',
  'Are you a Politically Prog':
    'Are you a Politically Exposed Person or are you related to a Politically Exposed Person?',
  'Full Name': 'Full Name',
  'Enter full Name': 'Enter full Name',
  'Minimum deposit': 'Minimum deposit',
  'Minimum deposit Prog': 'Minimum deposit',
  DGITrustTooltip: `A trusted contact is an individual authorized by an investor to be contacted by their financial firm in limited circumstances, such as concerns about activity in the investor's account or if the firm has been unable to reach the investor after numerous attempts.`,
  'Upload a Void check or Bank Statement': 'Upload a Void check or Bank Statement',
  'My Biz Account': 'My Biz Account',
  'Ahorro Comercial': 'Ahorro Comercial',
  'Commercial CD': 'Commercial CD',
  'NON-PROFIT': 'NON-PROFIT',
  'ESCROW ACCOUNT': 'ESCROW ACCOUNT',
  Months: 'Months',
  Years: 'Years',
  'Trusted Contact Information': 'Trusted Contact Information',
  'What is your employment status?': 'What is your employment status?',
  'What is your employment Prog': 'What is your employment status?',
  '* Should have month less than 12': '* Should have month less than 12',
  'To comply with regulation it is important we obtain your financial information and evaluate your general investment knowledge. Please select below.':
    'To comply with regulation it is important we obtain your financial information and evaluate your general investment knowledge. Please select below.',
  'Liquid Net Worth': 'Liquid Net Worth',
  'Employment Time Period': 'Employment Time Period',
  // edelivery
  'Use the options below to choose how you would like to receive your account information.':
    'Use the options below to choose how you would like to receive your account information.',
  'How would you like to receive your account information?':
    'How would you like to receive your account information?',
  'For your convenience, account communications will be delivered to you electronically.':
    'For your convenience, account communications will be delivered to you electronically.',
  'You will be notified by e-mail when documents become available for you to retrieve online at our website.':
    'You will be notified by e-mail when documents become available for you to retrieve online at our website.',
  'DGI Balanced Fund IRA Account Online Access': 'DGI Balanced Fund IRA Account Online Access',
  'Do you have a existing user ID?': 'Do you have a existing user ID?',
  'Enter user id': 'Enter user id',
  'New User': 'New User',
  'Please provide 2 user ID options. You will receive a email confirmation of enrollment once your account setup is completed?':
    'Please provide 2 user ID options. You will receive a email confirmation of enrollment once your account setup is completed?',
  'User ID Option 1': 'User ID Option 1',
  'User ID Option 2': 'User ID Option 2',
  'Enter your first option': 'Enter your first option',
  'Enter your second option': 'Enter your second option',
  'Taxpayer Certification for U.S. Person': 'Taxpayer Certification for U.S. Person',
  'This section is not to be used by non-U.S. person':
    'This section is not to be used by non-U.S. person',
  'Under penalties of perjury, I certify that:': 'Under penalties of perjury, I certify that:',
  '1. The number shown for the Primary Account Holder on this form is my correct Social Security Number or Taxpayer Identification Number (or I am waiting for a number to be issued to me);':
    '1. The number shown for the Primary Account Holder on this form is my correct Social Security Number or Taxpayer Identification Number (or I am waiting for a number to be issued to me);',
  '2. I am not subject to backup withholding because':
    '2. I am not subject to backup withholding because',
  'If you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For mortgage interest paid, acquisition or abandonment of secured property,cancellation of debt, contributions to an individual retirement arrangement (IRA),and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN.':
    'If you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For mortgage interest paid, acquisition or abandonment of secured property,cancellation of debt, contributions to an individual retirement arrangement (IRA),and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN.',
  'I am exempt from backup withholding or': 'I am exempt from backup withholding or',
  'I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or':
    'I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or',
  'the IRS has notified me that I am no longer subject to backup withholding; and':
    'the IRS has notified me that I am no longer subject to backup withholding; and',
  'If you are an exempt payee (if you are unsure, ask us for a complete set of IRS instructions), enter your exempt payee code (if any) here:':
    'If you are an exempt payee (if you are unsure, ask us for a complete set of IRS instructions), enter your exempt payee code (if any) here:',
  'If you are exempt from FATCA reporting (if you are unsure, ask us for a complete set of IRS instructions), enter your exemption from FATCA reporting code (if any) here:':
    'If you are exempt from FATCA reporting (if you are unsure, ask us for a complete set of IRS instructions), enter your exemption from FATCA reporting code (if any) here:',
  '3. I am a U.S. citizen or other U.S. person and':
    '3. I am a U.S. citizen or other U.S. person and',
  '4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.':
    '4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.',
  'Electronic Delivery': 'Electronic Delivery',
  'You will receive all communications via postal mail.':
    'You will receive all communications via postal mail.',
  '* Your Work Experience can not be more than or equal to Age':
    '* Your Work Experience can not be more than or equal to Age',

  'Definition of a U.S. person.': 'Definition of a U.S. person.',
  'For federal tax purposes, you are considered a U.S. person if you are:':
    'For federal tax purposes, you are considered a U.S. person if you are:',
  'An individual who is a U.S. citizen or U.S. resident alien,':
    'An individual who is a U.S. citizen or U.S. resident alien,',
  'A partnership, corporation, company or association created or organized in the United States or under the laws of the United States,':
    'A partnership, corporation, company or association created or organized in the United States or under the laws of the United States,',
  'An estate (other than a foreign estate), or': 'An estate (other than a foreign estate), or',
  'A domestic trust (as defined in Regulations section 301.7701-7)':
    'A domestic trust (as defined in Regulations section 301.7701-7)',
  'User ID': 'User ID',
  'Enter exempt payee code': 'Enter exempt payee code',
  'Enter User ID Option 1': 'Enter User ID Option 1',
  '* User ID should have min 7 and max 15 characters':
    '* User ID should have min 7 and max 15 characters',
  ConsentHelperTextDGI:
    'Before we engage in this transaction, you must understand your rights and responsibilities and provide your consent.',
  '* FATCA code should have min 8 and max 20 characters':
    '* FATCA code should have min 8 and max 20 characters',
  '* Exempt payee code should have min 8 and max 20 characters':
    '* Exempt payee code should have min 8 and max 20 characters',
  'Over 50%': 'Over 50%',
  PreviewStatementDgi:
    'Sensitive Information will be encrypted and safeguarded in our systems. A blank Void check or bank statement from the last 60 days is required to confirm your account. The numbering for the void check must be equal to or greater than 100. If the account is not currently under your name, additional information will be requested.',
  '* User ID Option 2 should not be same as User ID Option 1':
    '* User ID Option 2 should not be same as User ID Option 1',
  '* Invalid Account Number': '* Invalid Account Number',
  'Upload a Void Check or Bank Statement in order to confirm the ownership of your account':
    'Upload a Void Check or Bank Statement in order to confirm the ownership of your account',
  'Enter FATCA reporting code': 'Enter FATCA reporting code',
  ConsentHelperTextdgi:
    'Before we engage in this transaction, you must understand your rights and responsibilities and provide your consent.',
  'Your information was sent successfully. We will contact you in the next 72 hours to complete the account opening process.':
    'Your information was sent successfully. We will contact you in the next 72 hours to complete the account opening process.',
  '* Maximum deposit allowed is $5,000': '* Maximum deposit allowed is $5,000',
  'Tax Year': 'Tax Year',
  FundingScreenDisclaimer:
    'By clicking continue I authorize ORIENTAL BANK to make a one-time electronic debit for the amount included in the deposit amount field.',
  FundingScreenDisclaimerProg:
    'By clicking continue I authorize ORIENTAL BANK to make a one-time electronic debit for the amount included in the deposit amount field.',
  'Aria Label Marital Status': 'Marital Status Required Select Marital Status dropdown menu button',
  'Aria Label Tax Year': 'Tax Year Required Select Tax Year dropdown menu button',
  'Aria Label Annual Income': 'Annual Income Required Select Annual Income dropdown menu button',
  'Aria Label Net Worth': 'Net Worth Required Select Net Worth dropdown menu button',
  'Aria Label Liquid Net Worth':
    'Liquid Net Worth Required Select Liquid Net Worth dropdown menu button',
  'Aria Label Tax Bracket': 'Tax Bracket Required Select Tax Bracket dropdown menu button',
  'Aria Label What is your investment knowledge?':
    'What is your investment knowledge? Required Select What is your investment knowledge? dropdown menu button',
  'Aria Label Do you have investment experience in Equities?':
    'Do you have investment experience in Equities? Required Select Do you have investment experience in Equities? dropdown menu button',
  'Aria Label Do you have investment experience in Fixed Income?':
    'Do you have investment experience in Fixed Income? Required Select Do you have investment experience in Fixed Income? dropdown menu button',
  'Aria Label Do you have investment experience in Mutual Funds?':
    'Do you have investment experience in Mutual Funds? Required Select Do you have investment experience in Mutual Funds? dropdown menu button',
  'Aria Label What is your risk tolerance?':
    'What is your risk tolerance? Required Select What is your risk tolerance? dropdown menu button',
  'Aria Label What is your investment objective?':
    'What is your investment objective? Required Select What is your investment objective? dropdown menu button',
  'Aria Label How long do you plan to hold the asset before selling it?':
    'How long do you plan to hold the asset before selling it? Required Select How long do you plan to hold the asset before selling it? dropdown menu button',
  'Aria Label What are your Liquidity Needs?':
    'What are your Liquidity Needs? Required Select What are your Liquidity Needs? dropdown menu button',
  screenHeadingDgi:
    'Your information was sent successfully. We will contact you in the next 72 hours to complete the account contribution.',
  'DGI Account number': 'DGI Account number',
  'Money Services Business (MSB’s)': 'Money Services Business (MSB’s)',
  'Bail Bond Businesses': 'Bail Bond Businesses',
  'Pre Paid Card Sale': 'Pre Paid Card Sale',
  'Foreign Exchange Market': 'Foreign Exchange Market',
  'Pawn Shops': 'Pawn Shops',
  'Marijuana related business and services as well as drug paraphernilia related business':
    'Marijuana related business and services as well as drug paraphernilia related business',
  'Offshore corporations': 'Offshore corporations',
  'Collection Agencies': 'Collection Agencies',
  'Casinos or Slot Machines': 'Casinos or Slot Machines',
  'Foreign Correspondent Banks': 'Foreign Correspondent Banks',
  'Adult Entertainment Business': 'Adult Entertainment Business',
  'Precious Metal Dealers': 'Precious Metal Dealers',
  'Non-US Residents Aliens': 'Non-US Residents Aliens',
  'Tarot Card Reading': 'Tarot Card Reading',
  'International Banking Entities': 'International Banking Entities',
  'Third Party Payment Processors': 'Third Party Payment Processors',
  'Gambling Businesses and Internet Gambling': 'Gambling Businesses and Internet Gambling',
  'Private ATM Machines': 'Private ATM Machines',
  'Credit Restoration Businesses': 'Credit Restoration Businesses',
  'Employer Address line 1': 'Employer Address line 1',
  COUNTRY_MODAL_TEXT: "As per Oriental bank policy only US, PR, USVI ID's are accepted.",
  MSG_VOUCHED_MODAL: 'Upload a valid ID with your selfie to continue with your application.',
  'Social Security Number Commercial': 'Social Security Number',
  'Enter social security number commercial': 'Enter social security number',
  'Upload Business address documentation': 'Upload Business address documentation',
  'Upload Business owner address documentation': 'Upload Business owner address documentation',
  '* Business owner address document is required': '* Business owner address document is required',
  '* Business address document is required': '* Business address document is required',
  'An active Merchant Number to confirm your Business Name and Business Owner Name is required.':
    'An active Merchant Number to confirm your Business Name and Business Owner Name is required.',
  '* Please enter a valid DGI account number': '* Please enter a valid DGI account number',
  'Merchant Registration Certificate': 'Merchant Registration Certificate',
  'Merchant Number': 'Merchant Number',
  'Aria Label Entity Type': 'Entity Type Required Select Entity Type dropdown menu button',
  'Aria Label Annual Sales': 'Annual Sales Required Select Annual Sales dropdown menu button',
  'Aria Label Number of Employees':
    'Number of Employees Required Select Number of Employees dropdown menu button',
  'Yes, mail my debit card.': 'Yes, mail my debit card.',
  'No, I don’t want a debit card for this account.':
    'No, I don’t want a debit card for this account.',
  'Your account includes a Mastercard Debit Card. Do you want us to ship you the card? (Arrives in 4-6 business days)':
    'Your account includes a Mastercard Debit Card. Do you want us to ship you the card? (Arrives in 4-6 business days)',
  'Your account includes a Mastercard debit card. Do you want the card to be shipped? (Arrives in 4-6 business days)':
    'Your account includes a Mastercard debit card. Do you want the card to be shipped? (Arrives in 4-6 business days)',
  'Upload the required documents to open your account.':
    'Upload the required documents to open your account.',
  'Upload the required documents.': 'Upload the required documents.',
  'Drag file here or ': 'Drag file here or ',
  'Choose file': 'Choose file',
  'PNG, JPG and PDF files are allowed to upload.': 'PNG, JPG and PDF files are allowed to upload.',
  'Corporate Resolution': 'Corporate Resolution',
  'Certificate of Incorporation': 'Certificate of Incorporation',
  'Articles of Incorporation': 'Articles of Incorporation',
  'List of Directors and Officers': 'List of Directors and Officers',
  'Commercial Registration Certificate': 'Commercial Registration Certificate',
  'DBA Resolution': 'DBA Resolution',
  'LLC Resolution': 'LLC Resolution',
  'Certificate of Organization': 'Certificate of Organization',
  'Operational Agreement': 'Operational Agreement',
  'Deed of Trust': 'Deed of Trust',
  'Certification for Trust Registry': 'Certification for Trust Registry',
  'Certificate of Incumbency': 'Certificate of Incumbency',
  'Document Checklist': 'Document Checklist',
  'The file exceeds the maximum upload size (10 Mb).':
    'The file exceeds the maximum upload size (10 Mb).',
  'Only files with the following formats are allowed: PNG, JPG, PDF.':
    'Only files with the following formats are allowed: PNG, JPG, PDF.',
  'This account is not available for processing fund transfer. Please provide another account.':
    'This account is not available for processing fund transfer. Please provide another account.',
  'cancel icon': 'cancel icon',
  'As per Oriental Bank policy an active SURI Merchant number is required to open this account.':
    'As per Oriental Bank policy an active SURI Merchant number is required to open this account.',
  Yes: 'Yes',
  No: 'No',
  'Location Details': 'Location Details',
  'Merchant Details': 'Merchant Details',
  'Certificate Details': 'Certificate Details',
  'NAICS Codes': 'NAICS Codes',
  'Type Of Certificate': 'Type Of Certificate',
  'Commercial Loan': 'Commercial Loan',
  'Commercial Credit Card': 'Commercial Credit Card',
  'Commercial Line of Credit': 'Commercial Line of Credit',
  'Préstamo Comercial': 'Commercial Loan',
  'Tarjeta de Crédito Comercial': 'Commercial Credit Card',
  'Línea de Crédito Comercial': 'Commercial Line of Credit',
  // OCR
  'The name provided in the application doesn’t match with the utility bill uploaded. Please provide another bill with the applicant name.':
    'The name provided in the application doesn’t match with the utility bill uploaded. Please provide another bill with the applicant name.',
  'Utility Bill': 'Utility Bill',
  'Select Utility Bill Type': 'Select Utility Bill Type',
  'Supported file types: PNG, JPG, PDF.': 'Supported file types: PNG, JPG, PDF.',
  'Max. 10MB': 'Max. 10MB',
  'Uploading your documents is easy': 'Uploading your documents is easy',
  'Proceed by uploading whichever documents you have. We will send a link to your email to upload any missing documents.':
    'Proceed by uploading whichever documents you have. We will send a link to your email to upload any missing documents.',
  'Your documents have been successfully uploaded. We will reach out to you in case anything else is required.':
    'Your documents have been successfully uploaded. We will reach out to you in case anything else is required.',
  'Your Invitation has been completed and information has been captured successfully. We will reach out to you in case anything else is required.':
    'Your Invitation has been completed and information has been captured successfully. We will reach out to you in case anything else is required.',
  'Thank you!': 'Thank you!',
  'Uploading your documents is easy!': 'Uploading your documents is easy!',
  InviCaseClosed:
    'The page you are trying to access is not available as the application is not open. Please contact our customer service at 787-620-7963.',
  InviInitialCallError:
    "We couldn't proceed further due to a technical issue. Please try again after some time.",
  InviAllDetailsDone:
    'The link you are trying to access is not available as all the required details are submitted.',
  'As per Oriental Bank policy we are currently unable to establish a relationship due to the business bankruptcy.':
    'As per Oriental Bank policy we are currently unable to establish a relationship due to the business bankruptcy.',
  '* Please provide an email address different from other applicants.':
    '* Please provide an email address different from other applicants.',
  'We are not able to complete your application.': 'We are not able to complete your application.',
  'We were unable to validate your ID. As per Oriental Bank policy we are currently unable to establish a relationship.':
    'We were unable to validate your ID. As per Oriental Bank policy we are currently unable to establish a relationship.',
  "The selected utility bill type doesn't match with the bill uploaded. Please review the selected bill type.": `The selected utility bill type doesn't match with the bill uploaded. Please review the selected bill type.`,
  'Please upload LUMA or AAA utility bill in Spanish with the customer name and address section.':
    'Please upload LUMA or AAA utility bill in Spanish with the customer name and address section.',
  'The utility bill uploaded was issued more than 60 days ago. Please upload a bill issued within 60 days.':
    'The utility bill uploaded was issued more than 60 days ago. Please upload a bill issued within 60 days.',
  "Please upload LUMA or AAA utility bill with a valid date, the customer's name and the address section.": `Please upload LUMA or AAA utility bill with a valid date, the customer's name and the address section.`,
  "Please upload the first page of a LUMA utility image bill with valid date, the customer's name and the address section.": `Please upload the first page of a LUMA utility image bill with valid date, the customer's name and the address section.`,
  Title: 'Title',
  'Email id': 'Email id',
  'Phone number': 'Phone number',
  'Personal Quote': 'Personal Quote',
  'Please upload the first page of a LUMA utility bill with valid date, the customer’s name and the address section.':
    'Please upload the first page of a LUMA utility bill with valid date, the customer’s name and the address section.',
  'Authorized Officer - Internal Employee': 'Authorized Officer - Internal Employee',
  'Authorized Officer - External': 'Authorized Officer - External',
  'Corporate Title': 'Corporate Title',
  'Select Corporate title': 'Select Corporate title',
  'Accounting/Finance Manager': 'Accounting/Finance Manager',
  'Chief Financial Officer': 'Chief Financial Officer',
  'Chief Operating Officer': 'Chief Operating Officer',
  Controller: 'Controller',
  'Office Manager': 'Office Manager',
  President: 'President',
  Secretary: 'Secretary',
  'Specify Corporate Title': 'Specify Corporate Title',
  "Let's hear more about your business": `Let's hear more about your business`,
  'Is your business currently on bankruptcy?': 'Is your business currently on bankruptcy?',
  'Search SURI Merchant Number': 'Search SURI Merchant Number',
  'Search Here': 'Search Here',
  'Please enter a valid SURI Merchant Number to proceed.':
    'Please enter a valid SURI Merchant Number to proceed.',
  'An active SURI Merchant Number is required': 'An active SURI Merchant Number is required',
  'The information of my business shown by the system is correct.':
    'The information of my business shown by the system is correct.',
  'I confirm the changes made by me are correct.': 'I confirm the changes made by me are correct.',
  Edit: 'Edit',
  'You can drag and drop to upload the location details.':
    'You can drag and drop to upload the location details.',
  'Drag and drop or': 'Drag and drop or',
  'Maximum: 10MB': 'Maximum: 10MB',
  'Maximum: 3MB': 'Maximum: 3MB',
  'Over $25,000,000': 'Over $25,000,000',
  'Time in business': 'Time in business',
  'Select Time in business': 'Select Time in business',
  'Understanding your credit needs': 'Understanding your credit needs',
  'Credit Amount': 'Credit Amount',
  'Enter Credit Amount': 'Enter Credit Amount',
  'Reason for Credit Request': 'Reason for Credit Request',
  'Enter Reason': 'Enter Reason',
  'Do you have a collateral for the credit?': 'Do you have a collateral for the credit?',
  'Specify Collateral': 'Specify Collateral',
  Specify: 'Specify',
  'Let’s Understand your Participation in the Business':
    'Let’s Understand your Participation in the Business',
  'Do you have ownership in the business?': 'Do you have ownership in the business?',
  'Change Will you be a signer on this account?': 'Change Will you be a signer on this account?',
  'At least one additional participant needs to be added if the applicant is neither owner or signer':
    'At least one additional participant needs to be added if the applicant is neither owner or signer',
  'Completing your application is easy now!': 'Completing your application is easy now!',
  'Provide a link to additional participants by providing their contact detail and selecting their role below.':
    'Provide a link to additional participants by providing their contact detail and selecting their role below.',
  'Account Participant': 'Account Participant',
  'Select Account Participant': 'Select Account Participant',
  'By clicking below, you will navigate to an ID verification process':
    'By clicking below, you will navigate to an ID verification process',
  Camera: 'Camera',
  'Authorized Signer': 'Authorized Signer',
  'Beneficial Owner': 'Beneficial Owner',
  'Person With Admin Control': 'Person With Admin Control',
  'Add Participant': 'Add Participant',
  'You can send invite to only one person with administrative control under this application.':
    'You can send invite to only one person with administrative control under this application.',
  '(Upload ATM Rental or Purchase Documentation)': '(Upload ATM Rental or Purchase Documentation)',
  '(Upload your ACT 20 Certification)': '(Upload your ACT 20 Certification)',
  'You have successfully submitted your information.':
    'You have successfully submitted your information.',
  SuccessProg: 'You have successfully submitted your information.',
  'What comes next?': 'What comes next?',
  'will be contacting you in the next 48 business hours to understand your needs!':
    'will be contacting you in the next 48 business hours to understand your needs!',
  'You will receive a confirmation email with your application details. Please check your email if you requested an invitation or document upload link.':
    'You will receive a confirmation email with your application details. Please check your email if you requested an invitation or document upload link.',
  'Back to Home page': 'Back to Home page',
  'View Profile': 'View Profile',
  // 'You have been invited as an additional participant to provide your information':
  //   'You have been invited as an additional participant to provide your information',
  'Personal Information': 'Personal Information',
  'We’d love to hear more about you!': 'We’d love to hear more about you!',
  'We’d love Prog': 'We’d love to hear more about you!',
  'My mailing address and home address are the same.':
    'My mailing address and home address are the same.',
  'Does this account belong to you?': 'Does this account belong to you?',
  'Your Home Address Information': 'Your Home Address Information',
  '0 - 12 months': '0 - 12 months',
  '1 - 2 years': '1 - 2 years',
  '3 - 5 years': '3 - 5 years',
  '6 - 9 years': '6 - 9 years',
  '+10 years': '+10 years',
  'You have been invited as an additional participant of ':
    'You have been invited as an additional participant of ',
  ' to provide your information.': ' to provide your information.',
  Invitation: 'Invitation',
  'The file exceeds the maximum upload size (3 Mb).':
    'The file exceeds the maximum upload size (3 Mb).',
  'Document uploaded is not supported.': 'Document uploaded is not supported.',
  'Try Again': 'Try Again',
  'Start ID Verification': 'Start ID Verification',
  close: 'close',
  Others: 'Others',
  '* Invalid Amount': '* Invalid Amount',
  'Before we can open your account we are required to obtain your consent on the following:':
    'Before we can open your account we are required to obtain your consent on the following:',
  'Upload Owner Address Proof': 'Upload Owner Address Proof',
  'DGI Account Online Access': 'DGI Account Online Access',
  idProcess: 'Click button to validate your identity',
  'Personal Info Text Conti': `Our online form helps us get to know you and your needs. Upload driver's license or passport first and we will complete the form using your information.`,
  'Upload your Act 22 Certification issued by State Department':
    'Upload your Act 22 Certification issued by State Department',
  Cancel: 'Cancel',
  'QR C': 'QR C',
  o: 'o',
  'de Internal Generation': 'de Internal Generation',
  'Generate QR Code': 'Generate QR Code',
  'Product Category': 'Product Category',
  'Product Name': 'Product Name',
  Language: 'Language',
  'Commercial Customer': 'Commercial Customer',
  'Enter Customer Name': 'Enter Customer Name',
  nameTooltip: 'Name included on this field will be sent to FIS as the employer.',
  'Sales Advisor': 'Sales Advisor',
  'Enter Sales Advisor': 'Enter Sales Advisor',
  'E-Mail Address': 'E-Mail Address',
  'Enter E-Mail Address': 'Enter E-Mail Address',
  'Please confirm below details': 'Please confirm below details',
  'Product Category:': 'Product Category:',
  'Product Name:': 'Product Name:',
  'Language:': 'Language:',
  'Commercial Customer:': 'Commercial Customer:',
  'Sales Advisor:': 'Sales Advisor:',
  'E-Mail Address:': 'E-Mail Address:',
  'QR code and URL sent successfully to your registered E-Mail Address.':
    'QR code and URL sent successfully to your registered E-Mail Address.',
  English: 'English',
  Spanish: 'Spanish',
  '* Amount Cannot Exceed 1,000,000': '* Amount Cannot Exceed 1,000,000',
  'Your information was sent successfully. Your account changes will be reflected in the next 24 hours.':
    'Your information was sent successfully. Your account changes will be reflected in the next 24 hours.',
  'Your information was sent successfully. We will be sending the new account details to your email in the next 24 hours.':
    'Your information was sent successfully. We will be sending the new account details to your email in the next 24 hours.',
  'Visit orientalbank.com': 'Visit orientalbank.com',
  'e-Statements': 'e-Statements',
  'Your Libre account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our Online':
    'Your Libre account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our Online',
  ' You will be able to access your account statements electronically, free of charge, when you sign up for our ':
    ' You will be able to access your account statements electronically, free of charge, when you sign up for our ',
  ' Online Banking.': ' Online Banking.',
  '* Address Line 1 should have max 30 characters.':
    '* Address Line 1 should have max 30 characters.',
  ' You will be able to access your account statements electronically, free of charge, when you sign up for our ':
    ' You will be able to access your account statements electronically, free of charge, when you sign up for our ',
  '* Address Line 2 should have max 30 characters.':
    '* Address Line 2 should have max 30 characters.',
  ' Online Banking.': ' Online Banking.',
  prg: ' Online Banking.',
  'Register today to avoid charges.': 'Register today to avoid charges.',
  'If you prefer your statement on paper, you will have a monthly service charge of $2.00.':
    'If you prefer your statement on paper, you will have a monthly service charge of $2.00.',
  'Accounts whose primary signer is between ages 13 to 23 or 65 years of age or older, are exempt from this fee.':
    'Accounts whose primary signer is between ages 13 to 23 or 65 years of age or older, are exempt from this fee.',
  'Aria Label Product Category':
    'Product Category Required Select Product Category dropdown menu button',
  AddressProofGif:
    'Upload a utility bill with your name and address issued within the last 60 days',
  'Repeat Verification Process': 'Repeat Verification Process',
  'You can repeat the verification process up to 3 times in case some information has been fetched incorrectly.':
    'You can repeat the verification process up to 3 times in case some information has been fetched incorrectly.',
  'Save your application': 'Save your application',
  'We noticed you want to edit your address': 'We noticed you want to edit your address',
  'What is the reason:': 'What is the reason:',
  'That is my address, but it has errors.': 'That is my address, but it has errors.',
  'I have a new address.': 'I have a new address.',
  'You have 45 days from the date your account is opened to make your first deposit.':
    'You have 45 days from the date your account is opened to make your first deposit.',
  'You will receive an email with your new account details in the next 24 to 48 hrs.':
    'You will receive an email with your new account details in the next 24 to 48 hrs.',
  'You will receive an email with your new account details in the next 24 to 48 business hours.':
    'You will receive an email with your new account details in the next 24 to 48 business hours.',
  'We will review your application details in order to open your account.':
    'We will review your application details in order to open your account.',
  ReviewProg: 'We will review your application details in order to open your account.',
  'You have successfully submitted your application.':
    'You have successfully submitted your application.',
  'What Come Next?': 'What Come Next?',
  'Thank You!': 'Thank You!',
  'Save your progress': 'Save your progress',
  'Please verify your details': 'Please verify your details',
  'Save & Continue': 'Save & Continue',
  'There is already a saved application with the given information. Please continue your saved application by':
    'There is already a saved application with the given information. Please continue your saved application by',
  'Your application process will auto save': 'Your application process will auto save',
  'Your application is saved.': 'Your application is saved.',
  'You can continue your process.': 'You can continue your process.',
  ' clicking here ': ' clicking here ',
  'or continue this application without saving.': 'or continue this application without saving.',
  modaltext: `Your application progress will be saved and you will have 30 days to complete it. Once saved, you'll receive an email with instructions on how to retrieve and complete your application.`,
  'Access your saved application': 'Access your saved application',
  'Enter the following information to access your saved application.':
    'Enter the following information to access your saved application.',
  'By clicking continue, you authorize Oriental Bank to contact you via phone call, text message and email.':
    'By clicking continue, you authorize Oriental Bank to contact you via phone call, text message and email.',
  'Continue your application': 'Continue your application',
  'No active application found.': 'No active application found.',
  'The entered details will be verified with your information which has been saved from the previous application. Terms and conditions may have changed since you saved your application.':
    'The entered details will be verified with your information which has been saved from the previous application. Terms and conditions may have changed since you saved your application.',
  'We are opening your account': 'We are opening your account',
  'This will take only few seconds, please do not close this window.':
    'This will take only few seconds, please do not close this window.',
  'Thanks for your interest in Oriental.': 'Thanks for your interest in Oriental.',
  'Continue your application process from where you left!':
    'Continue your application process from where you left!',
  '* Invalid Date': '* Invalid Date',
  'Welcome to Oriental Bank!': 'Welcome to Oriental Bank!',
  'Welcome to Oriental Prog': 'Welcome to Oriental Bank!',
  'Details about your new account:': 'Details about your new account:',
  'Please write down your account number. You will need it to subscribe to Online Banking.':
    'Please write down your account number. You will need it to subscribe to Online Banking.',
  'Routing Number': 'Routing Number',
  'Routing Number Prog': 'Routing Number',
  'Opened Date': 'Opened Date',
  'You are ready for the next step.': 'You are ready for the next step.',
  'Yes, you can manage your bank from the palm of your hands.':
    'Yes, you can manage your bank from the palm of your hands.',
  ' Subscribe to the Online and Mobile Banking with only one click and start doing so now.':
    ' Subscribe to the Online and Mobile Banking with only one click and start doing so now.',
  'Subscribe Now': 'Subscribe Now',
  'Application cannot be saved. There is already a saved application with the given information.':
    'Application cannot be saved. There is already a saved application with the given information.',
  'Yes, you can manage your bank from the palm of your hands. Subscribe to the Online and Mobile Banking with only one click and start doing so now.':
    'Yes, you can manage your bank from the palm of your hands. Subscribe to the Online and Mobile Banking with only one click and start doing so now.',
  'Deposit, withdraw, send and receive money at your convenience.':
    'Deposit, withdraw, send and receive money at your convenience.',
  'With Online Banking and Digital Banking': 'With Online Banking and Digital Banking',
  'PHOTO Deposit': 'PHOTO Deposit',
  'Live Teller': 'Live Teller',
  'Automated Teller Machine': 'Automated Teller Machine',
  'ATH Móvil': 'ATH Móvil',
  'People Pay': 'People Pay',
  'out of 8 resend attempts remaining': 'out of 8 resend attempts remaining',
  "Haven't received the access code?": "Haven't received the access code?",
  'Resend new access code': 'Resend new access code',
  'By clicking submit, you agree to our': 'By clicking submit, you agree to our',
  'Terms of Use': 'Terms of Use',
  'Privacy Policy': 'Privacy Policy',
  and: 'and',
  'Enter the access code sent to your mobile/email below:':
    'Enter the access code sent to your mobile/email below:',
  '* Enter your Full SSN': '* Enter your Full SSN',
  'Mobile number': 'Mobile number',
  'Phone call': 'Phone call',
  'Email:': 'Email:',
  'out of 8 resend attempts remaining': 'out of 8 resend attempts remaining',

  Email: 'Email',
  Next: 'Next',
  'mobile number': 'mobile number',
  'phone number': 'phone number',
  email: 'email',
  OTPadditionalInfo:
    'Note: The listed phone numbers and emails are those currently on your record. For security purposes, you must select one of the listed numbers to complete this request.  If this information is outdated, please contact us at (PR) 787-620-0000 or (USVI) 1-800-981-5554 to help you.',
  'Text message': 'Text message',
  'for security we have to send you an unique access code. Choose how would you like to receive the access code:':
    'for security we have to send you an unique access code. Choose how would you like to receive the access code:',
  'Please enter the following information to continue:':
    'Please enter the following information to continue:',
  'Industry Type': 'Industry Type',
  'Select business industry type': 'Select business industry type',
  'View your new account in Online Banking.': 'View your new account in Online Banking.',
  'We’re sorry, we are not able to process your request at this time due to some information on your profile. Please continue as guest to open your account.':
    'We’re sorry, we are not able to process your request at this time due to some information on your profile. Please continue as guest to open your account.',
  'Select your Mailing Address': 'Select your Mailing Address',
  'I wish to add a new mailing address': 'I wish to add a new mailing address',
  'View your new account details in Online Banking.':
    'View your new account details in Online Banking.',
  'Go to Online Banking': 'Go to Online Banking',
  'Access code is valid': 'OTP verified successfully!',
  'Sorry, with the information provided, I can’t validate your identity. Contact us at (PR) 787.620.0000, (USVI) 1.800.981.5554 to help you.':
    'Sorry, with the information provided, I can’t validate your identity. Contact us at (PR) 787.620.0000, (USVI) 1.800.981.5554 to help you.',
  'Make paydays even better with Direct Deposit. Sign up to receive your payroll or other deposits directly in your account.':
    'Make paydays even better with Direct Deposit. Sign up to receive your payroll or other deposits directly in your account.',
  'Yes, I’m interested.': 'Yes, I’m interested.',
  'No, I’m not interested.': 'No, I’m not interested.',
  'Direct Deposit': 'Direct Deposit',
  '* Please enter a valid zipcode': '* Please enter a valid zipcode',
  'Invalid format': 'Invalid format',
  '* Please review the deposit amount entered as it is more than the available balance on this account':
    '* Please review the deposit amount entered as it is more than the available balance on this account',
  'Please review the deposit amount entered as it is more than the available balance on this account':
    '* Please review the deposit amount entered as it is more than the available balance on this account',
  'The maximum limit for initial deposit is $5,000.':
    'The maximum limit for initial deposit is $5,000.',
  'A minimum deposit of $25 is required to open this account.':
    'A minimum deposit of $25 is required to open this account.',
  'A minimum deposit of $1000 is required to open this account.':
    'A minimum deposit of $1000 is required to open this account.',
  'feature screen desc': 'Please review the features below which apply to your account.',
  '* Minimum excess limit amount is $1,000.00': '* Minimum excess limit amount is $1,000.00',
  'The account selected is dormant or inactive. Please review your selection.':
    'The account selected is dormant or inactive. Please review your selection.',
  'The account selected is Inactive. Please review the selection and proceed':
    'The account selected is Inactive. Please review the selection and proceed',
  // 'Your Elite account includes e-Statement!': 'Your Elite account includes e-Statement!',
  // 'Your elite account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our':
  // 'Your elite account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our',
  'If you prefer your statement on paper, you will have a monthly service charge of $2.00.':
    'If you prefer your statement on paper, you will have a monthly service charge of $2.00.',
  'This account requires maintaining a minimum amount of $1,000.00 in the checking section. To help you maintain this balance, we will automatically transfer from your savings account the necessary amount to maintain this limit as you make transactions.':
    'This account requires maintaining a minimum amount of $1,000.00 in the checking section. To help you maintain this balance, we will automatically transfer from your savings account the necessary amount to maintain this limit as you make transactions.',
  'In order for you to earn the highest interest rate payments on your savings, we can automatically transfer the excess of the amount you want. Tell us if you prefer to activate this function along with the maximum amount and we will take care of the rest.':
    'In order for you to earn the highest interest rate payments on your savings, we can automatically transfer the excess of the amount you want. Tell us if you prefer to activate this function along with the maximum amount and we will take care of the rest.',
  'Note: We will transfer the excess of this amount from your checking to your savings section.':
    'Note: We will transfer the excess of this amount from your checking to your savings section.',
  'Min amount $1,000.00': 'Min amount $1,000.00',
  'Excess limit amount': 'Excess limit amount',
  'Excess transfer': 'Excess transfer',
  'Deficit transfer': 'Deficit transfer',
  'Automatic Transfers': 'Automatic Transfers',
  'Combined statement:': 'Combined statement:',
  'Your account includes combined statement. You will be able to access your two statements together.':
    'Your account includes combined statement. You will be able to access your two statements together.',
  Statements: 'Statements',
  'Your initial deposit will be reflected in your ':
    'Your initial deposit will be reflected in your ',
  'CHECKING SECTION.': 'CHECKING SECTION.',
  ' Remember you can transfer your money to your saving section at any time on our Online Banking app.':
    ' Remember you can transfer your money to your saving section at any time on our Online Banking app.',
  'Where my initial deposit will go?': 'Where my initial deposit will go?',
  'WE RECOMMEND': 'WE RECOMMEND',
  'Verify with Plaid': 'Verify with Plaid',
  'It can help you control access to your data.': 'It can help you control access to your data.',
  'Verify Now': 'Verify Now',
  'Upload Bank Statement': 'Upload Bank Statement',
  'Upload a void check or bank statement.': 'Upload a void check or bank statement.',
  'Upload Now': 'Upload Now',
  'Supported file types: PNG, JPG, PDF. Maximum: 10MB':
    'Supported file types: PNG, JPG, PDF. Maximum: 10MB',
  'Uploaded successfully': 'Uploaded successfully',
  'Upload failed.File size exceeded': 'Upload failed.File size exceeded',
  'Upload failed. File format not supported': 'Upload failed. File format not supported',
  'Account details': 'Account details',
  'Accounts whose primary signer is 65 years of age or older, are exempt from this fee.':
    'Accounts whose primary signer is 65 years of age or older, are exempt from this fee.',
  'You’re all set!': 'You’re all set!',
  'We have verified the details of the bank account,':
    'We have verified the details of the bank account,',
  ' and you’re good to go.': ' and you’re good to go.',
  'Verification Failed': 'Verification Failed',
  'Your account verification is failed.Don’t worry you can upload your bank statement or ':
    'Your account verification is failed.Don’t worry you can upload your bank statement or ',
  'Try Again': 'Try Again',
  'Verification Pending': 'Verification Pending',
  'Your account verification is pending. We will send you an email to validate the microdeposits. However, you must complete the application so we can finalize the account verification.':
    'Your account verification is pending. We will send you an email to validate the microdeposits. However, you must complete the application so we can finalize the account verification.',
  'Where will my initial deposit go?': 'Where will my initial deposit go?',
  'In order for you to earn the highest interest rate payments on your savings, we can automatically':
    'In order for you to earn the highest interest rate payments on your savings, we can automatically',
  ' transfer the excess of the amount you want. Tell us if you prefer to activate this function along with the maximum amount and we will take care of the rest.':
    ' transfer the excess of the amount you want. Tell us if you prefer to activate this function along with the maximum amount and we will take care of the rest.',
  'Elite Account': 'Elite Account',
  'Cuenta Elite': 'Elite Account',
  'Account verification failed. Please try again.':
    'Account verification failed. Please try again.',
  'Account verification already completed.': 'Account verification already completed.',
  linkExpired:
    'Account verification link expired. Please contact our customer service at 787-777-7777.',
  'Entered SSN and/or Date of birth do not match with data provided in the application.':
    'Entered SSN and/or Date of birth do not match with data provided in the application.',
  'Account Type': 'Account Type',
  'Account information': 'Account information',
  'Please complete the account verification using Plaid or upload a check/bank statement.':
    'Please complete the account verification using Plaid or upload a check/bank statement.',
  'Complete account verification plaid': 'Complete account verification',
  'Enter the following information to complete account verification plaid.':
    'Enter the following information to complete account verification.',
  'plaid desc text':
    'The entered details will be verified with your information which has been saved from the previous application. Terms and conditions may have changed since you saved your application.',
  '* Invalid Business Name': '* Invalid Business Name',
  'Accounts whose primary signer is older than 65 years of age, are exempt from this fee.':
    'Accounts whose primary signer is older than 65 years of age, are exempt from this fee.',
  'If you prefer your statement on paper, you will have a monthly service charge of $2.00.':
    'If you prefer your statement on paper, you will have a monthly service charge of $2.00.',
  'Would you like to make an initial deposit to your account at this moment?':
    'Would you like to make an initial deposit to your account at this moment?',
  'Initial Deposit': 'Initial Deposit',
  ' includes e-Statement!': ' includes e-Statement!',
  'Your name ': 'Your ',
  'Libre account': 'Libre account',
  'Elite account': 'Elite account',
  'Ahorra account': 'Ahorra account',
  'We have verified the details of the bank account, and you’re good to go.':
    'We have verified the details of the bank account, and you’re good to go.',
  'Account number exceeds the maximum allowed limit':
    'Account number exceeds the maximum allowed limit',
  'Some details of your profile do not match our records. You can open your Elite account through the new customer process, or call us at 787.434.8458 for support with the process.':
    'Some details of your profile do not match our records. You can open your Elite account through the new customer process, or call us at 787.434.8458 for support with the process.',
  'Your account includes e-Statement. You will be able to access your account statements electronically and free of charge when you sign up for our':
    'Your account includes e-Statement. You will be able to access your account statements electronically and free of charge when you sign up for our',
  'We will need more information to complete this request, please continue as guest to open your account.':
    'We will need more information to complete this request, please continue as guest to open your account.',
  'Some details of your profile do not match our records. You can open your Ahorra account through the new customer process.':
    'Some details of your profile do not match our records. You can open your Ahorra account through the new customer process.',
};
